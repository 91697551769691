import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../servicio/general.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../servicio/authentication.service';

@Component({
  selector: 'app-configurar',
  templateUrl: './configurar.component.html',
  styleUrls: ['./configurar.component.scss']
})
export class ConfigurarComponent implements OnInit {
  verGeneral = true;
  verNosotros = false;
  verServicios = false;
  color = [{letra: '#FFFFFF', fondo: '#00052D', borde: '2px solid #00052D'},
           {letra: '#00052D', fondo: '#FFFFFF', borde: '2px solid #00052D'},
           {letra: '#00052D', fondo: '#FFFFFF', borde: '2px solid #00052D'}];
  configurar = {
    direccion: '',
    telefono: 0,
    whatsapp: []
  };
  nosotros = {
    dato_01: '',
    dato_02: '',
    dato_03: '',
    id_pagina: '',
    pagina: ''
  };
  whatsapp_01 = 0;
  whatsapp_02 = 0;

  constructor(private generalService: GeneralService, private dashboard: DashboardComponent, private router: Router,
              private snackBar: MatSnackBar, public authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.generalService.scAllDocuments('t_configuracion').subscribe((configurar: any) => {
      this.configurar.direccion = configurar[0].direccion;
      this.configurar.telefono = configurar[0].telefono;

      if (configurar[0].whatsapp.length > 0) {
        this.whatsapp_01 = configurar[0].whatsapp[0];

        if (configurar[0].whatsapp.length > 1) {
          this.whatsapp_02 = configurar[0].whatsapp[1];
        }
      }
    });

    this.generalService.scAllDocumentsConditional('t_pagina', 'pagina', '==', 'Nosotros').subscribe((nosotros: any) => {
      this.nosotros = nosotros[0];
    });
  }

  ver(num) {
    switch (num) {
      case 1:
        this.color[0].letra = '#FFFFFF';
        this.color[0].fondo = '#00052D';
        this.color[1].letra = '#00052D';
        this.color[1].fondo = '#FFFFFF';
        this.color[2].letra = '#00052D';
        this.color[2].fondo = '#FFFFFF';
        this.verGeneral = true;
        this.verNosotros = false;
        this.verServicios = false;
        break;
      case 2:
        this.color[0].letra = '#00052D';
        this.color[0].fondo = '#FFFFFF';
        this.color[1].letra = '#FFFFFF';
        this.color[1].fondo = '#00052D';
        this.color[2].letra = '#00052D';
        this.color[2].fondo = '#FFFFFF';
        this.verGeneral = false;
        this.verNosotros = true;
        this.verServicios = false;
        break;
      case 3:
        this.color[0].letra = '#00052D';
        this.color[0].fondo = '#FFFFFF';
        this.color[1].letra = '#00052D';
        this.color[1].fondo = '#FFFFFF';
        this.color[2].letra = '#FFFFFF';
        this.color[2].fondo = '#00052D';
        this.verGeneral = false;
        this.verNosotros = false;
        this.verServicios = true;
        break;
    }
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  actualizar(num) {
    switch (num) {
      case 1:
        if (this.whatsapp_01 > 0 && this.whatsapp_01 !== null) {
          this.configurar.whatsapp.push(this.whatsapp_01);
        }

        if (this.whatsapp_02 > 0 && this.whatsapp_02 !== null) {
          this.configurar.whatsapp.push(this.whatsapp_02);
        }

        this.generalService.updateDocument('t_configuracion', '1Zpsl9LOiksSUovXU4ub', this.configurar).then(successU => {
          this.openSnackBar('Configuración general actualizada correctamente.', '');
        });
        break;
      case 2:
        this.generalService.updateDocument('t_pagina', this.nosotros.id_pagina, this.nosotros).then(successU => {
          this.openSnackBar('Configuración nosotros actualizada correctamente.', '');
        });
        break;
      case 3:
        break;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
