import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, EmailValidator } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MailForm } from '../../servicio/mailForm';
import { SendEmailService } from '../../servicio/send-email.service';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from '../../servicio/general.service';
import { AuthenticationService } from '../../servicio/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

import * as domtoimage from 'dom-to-image';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';

export interface Dialogo {
  tbl: string;
  idP: string;
  cdg: string;
  tipo: string;
  precio: number;
  mon: string;
}

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {
  tipoProp;
  comuna;
  propiedades;
  propiedad;
  imgPrin = [];
  imagenes = [];
  imgCel = [];
  buscarURL = '';
  meses = [{num: 1, num2: '01', mes: 'Enero'}, {num: 2, num2: '02', mes: 'Febrero'}, {num: 3, num2: '03', mes: 'Marzo'}, {num: 4, num2: '04', mes: 'Abril'},
           {num: 5, num2: '05', mes: 'Mayo'}, {num: 6, num2: '06', mes: 'Junio'}, {num: 7, num2: '07', mes: 'Julio'}, {num: 8, num2: '08', mes: 'Agosto'},
           {num: 9, num2: '09', mes: 'Septiembre'}, {num: 10, num2: '10', mes: 'Octubre'}, {num: 11, num2: '11', mes: 'Noviembre'}, {num: 12, num2: '12', mes: 'Diciembre'}];
  coordenadas = false;
  imgSlide = '';
  showNavigationArrows = true;
  showNavigationIndicators = false;

  constructor(private generalService: GeneralService, public authenticationService: AuthenticationService, private meta: Meta,
              private rutaActiva: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    if (this.rutaActiva.snapshot.params.url !== undefined) {
      this.buscarURL = this.rutaActiva.snapshot.params.url;
    }
  }

  dialogo(tabla, idProp, codigo, tipoP, valor, moneda): void {
    if (tabla === 'Imagen') {
      this.dialog.open(DialogoDetalle, {
        width: '90%',
        height: '600px',
        data: {tbl: tabla, idP: idProp, cdg: codigo, tipo: tipoP, precio: valor, mon: moneda}
      });
    } else {
      this.dialog.open(DialogoDetalle, {
        width: '80%',
        data: {tbl: tabla, idP: idProp, cdg: codigo, tipo: tipoP, precio: valor, mon: moneda}
      });
    }
  }

  ngOnInit(): void {
    localStorage.setItem('load', 'true');

    const valW = +localStorage.getItem('width');
    const valWR = Math.round((valW * 20) / 100) + 130;
    const calc = Math.round((valW - valWR) / 4);
    this.imgSlide = calc + 'px';
    this.propiedad = [];

    this.generalService.getAllDocumentsConditional('t_propiedad', 'url', '==', this.buscarURL).subscribe((infoProp: any) => {
      this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', infoProp[0].id_tipo_prop).subscribe((tipoProp: any) => {
        this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', infoProp[0].id_propiedad, 'principal', '==', true).subscribe((principal: any) => {
          this.coordenadas = false;
          infoProp[0].principal = '../../../assets/img/sinFoto.png';

          if (principal.length > 0) {
            infoProp[0].principal = principal[0].url;
          }
          //localStorage.setItem('coorX', '0');
          //localStorage.setItem('coorY', '0');

          if (infoProp[0].coorX !== 0 && infoProp[0].coorY !== 0) {
            //localStorage.setItem('coorX', infoProp[0].coorX);
            //localStorage.setItem('coorY', infoProp[0].coorY);
            this.coordenadas = true;
          }
          
          this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', infoProp[0].id_comuna).subscribe((comuna: any) => {
            infoProp[0].id_comuna = comuna[0].comuna;
          });
          infoProp[0].id_tipo_prop = tipoProp[0].tipo_prop;

          this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', infoProp[0].id_moneda).subscribe((moneda: any) => {
            infoProp[0].id_moneda = moneda[0].moneda;
          });

          if (infoProp[0].negocio === 'A') {
            infoProp[0].negocio = 'Arriendo';
          } else {
            infoProp[0].negocio = 'Venta';
          }
          this.propiedad = infoProp[0];

          const metaTag: MetaDefinition = {
            name: 'description',
            content: this.propiedad.titulo + ' ' + this.propiedad.id_moneda + ' ' + this.propiedad.precio
          };
          this.updateMeta(metaTag);

          this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', infoProp[0].id_propiedad, 'estado', '==', true).subscribe((imagenes: any) => {
            this.imagenes = imagenes;
            localStorage.setItem('load', 'false');
            /*this.imgCel = [];
            this.imgPrin = [{id: '', url: ''}, {id: '', url: ''}, {id: '', url: ''}, {id: '', url: ''}];

            if (imagenes.length > 0) {
              let cantS = 0;
              let cantST = 0;
              let cantI = 1;

              imagenes.forEach((imagen: any) => {
                this.imgCel.push({id: imagen.id_propiedad_img, url: imagen.url});

                if (cantS === 0) {
                  this.imagenes.push([{id: '', url: ''}, {id: '', url: ''}, {id: '', url: ''}, {id: '', url: ''}]);
                }
                this.imagenes[cantST][cantS].id = imagen.id_propiedad_img;
                this.imagenes[cantST][cantS].url = imagen.url;

                if (cantS === 3) {
                  cantST++;
                }
                cantS++;
      
                if (cantS === 4) {
                  cantS = 0;
                }

                if (cantI === imagenes.length) {
                  localStorage.setItem('load', 'false');
                }
                cantI++;
              });
            } else {
              this.imagenes = [];
              this.imgCel = [];
              localStorage.setItem('load', 'false');
            }*/
          });
        });
      });
    });
  }

  volver() {
    this.router.navigate(['../../propiedades'], {relativeTo: this.rutaActiva});
  }

  pdf(id) {
    this.generalService.getAllDocumentsConditional('t_configuracion', 'id_configuracion', '==', '1Zpsl9LOiksSUovXU4ub').subscribe((config: any) => {
      this.generalService.getAllDocumentsConditional('t_propiedad', 'id_propiedad', '==', id).subscribe((infoProp: any) => {
        domtoimage.toPng(document.getElementById('propiedad'))
        .then(function (blob) {
          let meses = [{num: 1, num2: '01', mes: 'Enero'}, {num: 2, num2: '02', mes: 'Febrero'}, {num: 3, num2: '03', mes: 'Marzo'}, {num: 4, num2: '04', mes: 'Abril'},
          {num: 5, num2: '05', mes: 'Mayo'}, {num: 6, num2: '06', mes: 'Junio'}, {num: 7, num2: '07', mes: 'Julio'}, {num: 8, num2: '08', mes: 'Agosto'},
          {num: 9, num2: '09', mes: 'Septiembre'}, {num: 10, num2: '10', mes: 'Octubre'}, {num: 11, num2: '11', mes: 'Noviembre'}, {num: 12, num2: '12', mes: 'Diciembre'}];

          //var pdf = new jsPDF('l', 'pt', [$('gridster').width(), $('gridster').height()]);
          //pdf.addImage(blob, 'PNG', 0, 0, $('gridster').width(), $('gridster').height());
          var pdf = new jsPDF();
          const logo = new Image();
          let mes = new Date().getMonth();
          let fecha = 'Valdivia, ';

          logo.src = config[0].logo;

          if (new Date().getDate() < 10) {
            fecha = fecha + '0' + new Date().getDate() + ' de ';
          } else {
            fecha = fecha + new Date().getDate() + ' de ';
          }
          fecha = fecha + meses[mes].mes + ' de ' + new Date().getFullYear();

          pdf.setFontSize(8);
          pdf.text(fecha, 140, 10);
          pdf.addImage(logo, 'PNG', 3, 3, 40, 20);
          pdf.setFontSize(16);
          pdf.setTextColor('#000000');
          pdf.setFont('verdana', 'normal');
          pdf.text('Propiedad Cód. ' + infoProp[0].codigo, 105, 20, {align: 'center'});
          pdf.setFontSize(10);
          pdf.addImage(blob, 'PNG', 3, 30, 200, 150);
          pdf.text('Contáctanos', 105, 260, {align: 'center'});
          pdf.text('63 2 580483', 105, 265, {align: 'center'});
          pdf.text('+56 9 5607 0310 / +56 9 7849 8825', 105, 270, {align: 'center'});
          pdf.text('secretaria@colladokunstmann.cl', 105, 275, {align: 'center'});
          pdf.save('Propiedad Cód. ' + infoProp[0].codigo + ' .pdf');
        });
      });
    });
  }

  updateMeta(metaTag: MetaDefinition): void {
    this.meta.updateTag(metaTag);
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: './dialogo.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DialogoDetalle implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new MailForm();
  arrayI = [];
  urlI = '';
  numSel = 0;
  verBoton = true;
  private emailResponse;
  private truefalse: boolean = false;

  constructor(private sendServices: SendEmailService, public snackBar: MatSnackBar,
              public dialogo: MatDialogRef<Dialogo>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo,
              private generalService: GeneralService) {
    if (dialog.tbl === 'Imagen') {
      this.generalService.getAllDocumentsConditional('t_propiedad_img', 'id_propiedad_img', '==', dialog.idP).subscribe((imgP: any) => {
        this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', imgP[0].id_propiedad, 'estado', '==', true).subscribe((imagenes: any) => {
          let num = 0;

          imagenes.forEach((imagen: any) => {
            if (imagen.id_propiedad_img === dialog.idP) {
              this.numSel = num;
              this.urlI = imagen.url;
            }
            this.arrayI.push(imagen);
            num++;
          });
        });
      });
    }
  }

  ngOnInit() {
  }

  siguiente() {
    if ((this.numSel + 1) < this.arrayI.length) {
      this.numSel++;
      this.urlI = this.arrayI[this.numSel].url;
    } else {
      this.urlI = this.arrayI[0].url;
      this.numSel = 0;
    }
  }

  anterior() {
    if (this.numSel === 0) {
      this.numSel = this.arrayI.length - 1;
      this.urlI = this.arrayI[this.numSel].url;
    } else {
      this.numSel--;
      this.urlI = this.arrayI[this.numSel].url;
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo invalido' :
        '';
  }

  onSubmit(f: NgForm, tipoF) {
    this.verBoton = false;
    this.ContactModel.tipo = tipoF;
    this.ContactModel.dato1 = 'Contacto';
    this.ContactModel.dato6 = this.dialog.cdg + ' - ' + this.dialog.tipo + ' - ' + this.dialog.mon + ' ' + this.dialog.precio;
    this.getSentServices(this.ContactModel, f);
  }

  getSentServices(body: MailForm, f: NgForm) {
    this.sendServices.getResponseEmail(body).subscribe(
      data => {
        if (data) {
          const objN = {
            asunto: this.dialog.cdg + ' - ' + this.dialog.tipo + ' - ' + this.dialog.mon + ' ' + this.dialog.precio,
            email: this.ContactModel.dato4,
            estado: 'N',
            fecha_crea: new Date().getTime(),
            id_consulta: '',
            mensaje: this.ContactModel.dato7,
            nombre: this.ContactModel.dato3,
            telefono: this.ContactModel.dato5,
            tipo: 'P'
          }
          this.generalService.newDocument(objN, 't_consulta').then(successI => {
            const update = {
              id_consulta: successI.id
            };
            this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
              this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
                duration: 5000,
              });
              this.dialogo.close();
            });
          });
        } else {
          this.snackBar.open(':(', 'Error', {
            duration: 2000,
          });
        }
      },
      err => { /*this.snackBar.open('Algo fallo :/', 'ups', {
        duration: 5000,
      });*/
      const objN = {
        asunto: this.dialog.cdg + ' - ' + this.dialog.tipo + ' - ' + this.dialog.mon + ' ' + this.dialog.precio,
        email: this.ContactModel.dato4,
        estado: 'N',
        fecha_crea: new Date().getTime(),
        id_consulta: '',
        mensaje: this.ContactModel.dato7,
        nombre: this.ContactModel.dato3,
        telefono: this.ContactModel.dato5,
        tipo: 'P'
      }
      this.generalService.newDocument(objN, 't_consulta').then(successI => {
        const update = {
          id_consulta: successI.id
        };
        this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
          this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
            duration: 5000,
          });
          this.dialogo.close();
        });
      });
    });
  }
}
