<nav class="navbar navbar-expand-lg" [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra')}">
    <div class="container-fluid">
        <a class="navbar-brand" href="https://colladokunstmann.cl/">
            <img src="{{authenticationService.localStorageItem('logo')}}" alt="Logo" width="180" height="auto" class="d-inline-block align-text-top">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <mat-icon style="cursor: pointer; font-size: 50px; color: white;">reorder</mat-icon>
        </button>
        <div *ngIf="authenticationService.localStorageItem('login') === 'false'" class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item"><a href="#" class="nav-link px-2" routerLink="" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">Inicio</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2" routerLink="propiedades" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">Propiedades</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2" routerLink="servicios" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">Servicios</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2" routerLink="nosotros" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">Nosotros</a></li>
                <li class="nav-item"><a href="#" class="nav-link px-2" routerLink="contacto" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">Contacto</a></li>
            </ul>
        </div>
        <div *ngIf="authenticationService.localStorageItem('login') === 'true'" class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">{{authenticationService.localStorageItem('nombre')}}<br>{{authenticationService.localStorageItem('perfil')}}</li>
            </ul>
        </div>
        <button *ngIf="authenticationService.localStorageItem('login') === 'false'" type="button" class="btn btn-primary" routerLink="login">LOGIN</button>
        <mat-icon *ngIf="authenticationService.localStorageItem('login') === 'true'" (click)="signOut()" title="Cerrar sesión" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}" style="font-size: 40px; margin-right: 40px; cursor: pointer;">clear</mat-icon>
    </div>
</nav>