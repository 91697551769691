<div *ngIf="authenticationService.localStorageItem('load') === 'true'" style="height: 300px;">
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-info m-5" role="status" style="width: 3rem; height: 3rem;">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="authenticationService.localStorageItem('load') === 'false'" style="margin-top: 40px; margin-bottom: 40px; width: 70%; margin-left: 15%;">
  <div id="propiedad" class="row" style="width: 100%;">
    <div class="col-lg-6"><img src="{{propiedad.principal}}" style="width: 100%; height: auto;"></div>
    <div class="col-lg-6" style="text-align: right; font-family: Roboto;">
      <div class="row" style="width: 90%; margin-left: 5%; margin-top: 20px; text-align: left; font-size: 30px; font-weight: bold;">
        <div class="col">{{propiedad.negocio}} | {{propiedad.titulo}}</div>
      </div>
      <div class="row" style="width: 90%; margin-left: 5%; margin-top: 20px; text-align: left; font-size: 35px;">
        <div class="col">{{propiedad.id_moneda}} {{propiedad.precio | number}}</div>
      </div>
      <div class="row" style="width: 90%; margin-left: 5%; margin-top: 20px; text-align: left;">
        <div class="col">
          <a href="https://api.whatsapp.com/send?text=https://colladokunstmann.cl/%23/detalle/{{propiedad.url}}" data-psfw-href="https://api.whatsapp.com/send?text=" target="_blank" title="Compartir por whatsapp" style="color: black;"><i class="fa-brands fa-whatsapp fa-bounce fa-lg" style="cursor: pointer; margin-right: 20px;"></i></a>
          <i class="fa-solid fa-envelope fa-lg" title="Contactar" style="cursor: pointer; margin-right: 20px;" (click)="dialogo('Contacto', propiedad.id_propiedad, propiedad.negocio, propiedad.titulo, propiedad.precio, propiedad.id_moneda)"></i>
          <i class="fa-solid fa-file-lines fa-lg" title="Descargar PDF" style="cursor: pointer;" (click)="pdf(propiedad.id_propiedad)"></i>
        </div>
      </div>
      <div class="row" style="width: 90%; margin-left: 5%; margin-bottom: 40px; margin-top: 20px;">
        <div class="col">
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;">Código: {{propiedad.codigo}}</div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;">Tipo propiedad: {{propiedad.id_tipo_prop}}</div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;"><div *ngIf="propiedad.mt_total > 0">Metros cuadrados totales: {{propiedad.mt_total}}</div></div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;"><div *ngIf="propiedad.mt_construido > 0">Metros cuadrados construidos: {{propiedad.mt_construido}}</div></div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;"><div *ngIf="propiedad.dormitorio > 0">Dormitorios: {{propiedad.dormitorio}}</div></div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;"><div *ngIf="propiedad.bagno > 0">Baños: {{propiedad.bagno}}</div></div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;"><div *ngIf="propiedad.estacionamiento > 0">Estacionamientos: {{propiedad.estacionamiento}}</div></div>
          </div>
          <div class="row">
            <div class="col" style="padding-top: 5px; padding-bottom: 5px; text-align: left;"><div *ngIf="propiedad.bodega > 0">Bodegas: {{propiedad.bodega}}</div></div>
          </div>
        </div>
      </div>
      <div class="row" style="width: 88%; margin-left: 6%;">
        <div style="width: 100%; padding: 15px; text-align: left;">
          <h4>Descripción de la propiedad:</h4>
          {{propiedad.detalle}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" style="margin-top: 40px; margin-bottom: 40px; width: 70%; margin-left: 15%;">
  <div class="col-lg-6">
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-md-4 g-3">
      <div *ngFor="let img of imagenes" class="col">
        <div class="shadow-sm">
          <img src="{{img.url}}" style="width: 100%; height: 150px; cursor: pointer;" (click)="dialogo('Imagen', img.id_propiedad_img, '', '', '', '')">
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6" style="text-align: right; font-family: Roboto;"></div>
</div>
<app-pie></app-pie>
