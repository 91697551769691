import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../servicio/authentication.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {
  agno = 0;
  whatsapp = '';

  constructor(public authenticationService: AuthenticationService) {
    this.whatsapp = '' + this.authenticationService.localStorageItem('numChat');
  }

  ngOnInit() {
    const fecha = new Date();
    this.agno = fecha.getFullYear();
  }
}
