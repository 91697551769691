import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { HostListener } from "@angular/core";
import { Title } from '@angular/platform-browser';

import * as CryptoJS from 'crypto-js';
declare var $: any;

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss']
})
export class EncabezadoComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;
  borde = '';
  urlTree;

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?)
    {
      localStorage.setItem('movil', 'false');
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      localStorage.setItem('width', this.scrWidth);

      if (this.scrWidth < 1100) {
        localStorage.setItem('movil', 'true');
      }
      // console.log(this.scrHeight, this.scrWidth);
    }

  constructor(public authenticationService: AuthenticationService, private router: Router, private rutaActiva: ActivatedRoute,
              private generalService: GeneralService, private titleService: Title) {
    this.getScreenSize();
    this.borde = '2px solid ' + localStorage.getItem('letra');
    
    this.generalService.getAllDocuments('t_configuracion').subscribe((config: any) => {
      localStorage.setItem('icono', CryptoJS.AES.encrypt(config[0].icono, 'CK2020').toString());
      localStorage.setItem('logo', CryptoJS.AES.encrypt(config[0].logo, 'CK2020').toString());
      localStorage.setItem('titulo', config[0].titulo);
      localStorage.setItem('barra', config[0].color_barra);
      localStorage.setItem('letra', config[0].color_letra);
      localStorage.setItem('letra_enc', config[0].color_letra_enc);
      localStorage.setItem('boton', config[0].color_boton);
      localStorage.setItem('direccion', config[0].direccion);
      localStorage.setItem('contacto', config[0].contacto);
      localStorage.setItem('telefono', config[0].telefono);
      localStorage.setItem('numChat', config[0].numChat);

      let cant = 0;
      let whatsapp = '';

      if (config[0].whatsapp.length > 0) {
        while (cant < config[0].whatsapp.length) {
          if (whatsapp === '') {
            //localStorage.setItem('numChat', config[0].whatsapp[cant]);
            whatsapp = config[0].whatsapp[cant];
          } else {
            whatsapp = whatsapp + ' / ' + config[0].whatsapp[cant];
          }
          localStorage.setItem('whatsapp' + cant, config[0].whatsapp[cant]);
          cant++;
  
          if (cant === config[0].whatsapp.length) {
            localStorage.setItem('whatsapp', whatsapp);
          }
        }
      } else {
        localStorage.setItem('whatsapp', '');
      }
      localStorage.setItem('facebook', config[0].facebook);
      this.borde = '2px solid ' + config[0].color_letra;
      this.titleService.setTitle(config[0].titulo);

      if (config[0].icono !== '') {
        $('#appFavicon').attr('href', this.authenticationService.localStorageItem('icono'));
      }
    });
  }

  ngOnInit() {
    localStorage.setItem('load', 'N');

    if (localStorage.getItem('login') === 'true') {
      if (localStorage.getItem('web') === 'CK') {
        this.router.navigateByUrl('dashboard');
      }
    } else {
      const arrayURL = window.location.href.split('/detalle/');

      if (arrayURL.length === 2) {
        const arrayURL2 = arrayURL[1].split('/');
        
        this.router.navigate(['/detalle', arrayURL2[0]])
      } else {
        localStorage.setItem('web', '');
        localStorage.setItem('id_usuario', '');
        localStorage.setItem('perfil', '');
        localStorage.setItem('nombre', '');
        localStorage.setItem('login', 'false');
        localStorage.setItem('load', 'N');
        this.router.navigateByUrl('');
      }
    }
  }

  signOut() {
    this.authenticationService.SignOut();
  }

  cerrarM() {
    $('.navbar-collapse').collapse('hide');
  }
}
