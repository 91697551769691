<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 *ngIf="dialog.tbl !== 'CC'" mat-dialog-title style="text-align: center;">{{consulta.tipo}}</h1>
<h1 *ngIf="dialog.tbl === 'CC'" mat-dialog-title style="text-align: center;">Ingresar consulta</h1>
<div mat-dialog-content>
  <div *ngIf="dialog.tbl === 'VI'">
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Fecha</div>
      <div class="col-8">{{ txtFecha | date:'dd-MM-yyyy' }}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Nombre</div>
      <div class="col-8">{{consulta.nombre}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Mail</div>
      <div class="col-8">{{consulta.email}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Teléfono</div>
      <div class="col-8">{{consulta.telefono}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Asunto</div>
      <div class="col-8">{{consulta.asunto}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Mensaje</div>
      <div class="col-8">{{consulta.mensaje}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Estado</div>
      <div class="col-8">{{consulta.estado}}</div>
    </div>
    <h3 style="margin-bottom: 2%; color: rgb(22, 62, 85)">Seguimiento</h3>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold; width: 20%;"> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_crea | date:'dd-MM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="observacion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold; width: 60%;"> Observación </th>
            <td mat-cell *matCellDef="let element"> {{element.observacion}} </td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold; width: 20%;">Estado</th>
            <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
  <div *ngIf="dialog.tbl === 'AI'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtObservacion" style="background-color: white; border: none;">Observacion</span>
      </div>
      <textarea class="form-control" rows="5" aria-label="Sizing example input" aria-describedby="txtObservacion" [(ngModel)]="seguimiento.observacion"></textarea>
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtRegion" style="background-color: white; border: none;">Estado</span>
      </div>
      <select class="custom-select" id="cbRegion" style="width: 70%;" placeholder="Seleccionar" [(ngModel)]="seguimiento.estado">
        <option value="" selected>Seleccionar</option>
        <option value="G">Gestionando</option>
        <option value="F">Finalizado</option>
      </select>
    </div>
  </div>
  <div *ngIf="dialog.tbl === 'CC'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtRegion" style="background-color: white; border: none;">Tipo</span>
      </div>
      <select class="custom-select" id="cbRegion" placeholder="Seleccionar" [(ngModel)]="consulta.tipo">
        <option value="" selected>Seleccionar</option>
        <option value="F">Facebook</option>
        <option value="O">Oficina</option>
        <option value="I">Portal Inmobiliario</option>
        <option value="T">Teléfono</option>
      </select>
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtNombre" style="background-color: white; border: none;">Nombre</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtNombre" [(ngModel)]="consulta.nombre">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtEmail" style="background-color: white; border: none;">Email</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtEmail" [(ngModel)]="consulta.email">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtTelefono" style="background-color: white; border: none;">Teléfono</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtTelefono" [(ngModel)]="consulta.telefono">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtAsunto" style="background-color: white; border: none;">Asunto</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtAsunto" [(ngModel)]="consulta.asunto">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtMensaje" style="background-color: white; border: none;">Mensaje</span>
      </div>
      <textarea class="form-control" rows="5" aria-label="Sizing example input" aria-describedby="txtMensaje" [(ngModel)]="consulta.mensaje"></textarea>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button *ngIf="dialog.tbl === 'CC'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="ingresar()">Ingresar</button>
  <button *ngIf="dialog.tbl === 'AI'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="actualizar()">Actualizar</button>
</div>
