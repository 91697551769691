<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
  <div class="input-group input-group-sm mb-3">
    <div class="input-group-prepend" style="width: 30%;">
      <span class="input-group-text" id="txtMoneda" style="background-color: white; border: none;">Moneda</span>
    </div>
    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtMoneda" [(ngModel)]="moneda.moneda">
  </div>
</div>
<div mat-dialog-actions>
    <button mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="crear()">{{dialog.btn}}</button>
</div>
