<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 30%;">
            <span class="input-group-text" id="cbPais" style="background-color: white; border: none;">País</span>
        </div>
        <select class="custom-select" id="cbPais" placeholder="Seleccionar" [(ngModel)]="comuna.id_pais" (change)="cargarReg()">
            <option value="" selected>Seleccionar</option>
            <option *ngFor="let pais of paises" [value]="pais.id_pais">{{pais.pais}}</option>
        </select>
    </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 30%;">
            <span class="input-group-text" id="cbRegion" style="background-color: white; border: none;">Región</span>
        </div>
        <select class="custom-select" id="cbRegion" placeholder="Seleccionar" [(ngModel)]="comuna.id_region">
            <option value="" selected>Seleccionar</option>
            <option *ngFor="let region of regiones" [value]="region.id_region">{{region.region}}</option>
        </select>
    </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 30%;">
            <span class="input-group-text" id="txtComuna" style="background-color: white; border: none;">Comuna</span>
        </div>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtComuna" [(ngModel)]="comuna.comuna">
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="crear()">{{dialog.btn}}</button>
</div>
