import { Injectable } from '@angular/core';
import { MailForm } from './mailForm';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class SendEmailService {
  type: any;
  constructor( private http: HttpClient) { }

  getResponseEmail(Body: MailForm): Observable<any> {
    return this.http.post('https://mail.colladokunstmann.cl/enviarMailCK.php', Body);
  }
}
