import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { StorageService } from './../../servicio/storage.service';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';

export interface Dialogo {
  tbl: string;
  idP: string;
  tit: string;
  btn: string;
}

export interface eleProp {
  bagno: number,
  bodega: number,
  ciudad: string,
  coorX: number,
  coorY: number,
  destacada: boolean,
  destacada_num: number,
  detalle: string,
  direccion: string,
  dormitorio: number,
  estacionamiento: number,
  estado: boolean,
  estado_prop: string,
  fecha_crea: number,
  id_comuna: string,
  id_moneda: string,
  id_pais: string,
  id_propiedad: string,
  id_proveedor: string,
  id_region: string,
  id_tipo_prop: string,
  mt_construido: number,
  mt_total: number,
  negocio: string,
  oferta: number,
  orden: number,
  precio: number,
  publicar: boolean,
  sector: string,
  titulo: string
}

@Component({
  selector: 'app-propiedad',
  templateUrl: './propiedad.component.html',
  styleUrls: ['./propiedad.component.scss']
})
export class PropiedadComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['codigo', 'tipo_prop', 'propietario', 'direccion', 'titulo', 'precio', 'orden', 'fotos', 'estado', 'publicar', 'destacar', 'vend_arr', 'accion'];
  estadoP = 'H';

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private dashboard: DashboardComponent, private dom: DomSanitizer) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dialogo(tabla, id) {
    let titulo = '';
    let boton = '';

    switch (tabla) {
      case 'CP':
        titulo = 'Nueva Propiedad';
        boton = 'Crear Propiedad';
        break;
      case 'EP':
        titulo = 'Editar Propiedad';
        boton = 'Editar Propiedad';
        break;
      case 'CIP':
        titulo = 'Galería imagenes';
        break;
      case 'DP':
        titulo = 'Propiedades destacadas';
        boton = 'Guardar cuadrícula';
        break;
      case 'OP':
        titulo = 'Orden Propiedad';
        boton = 'Guardar';
        break;
    }
    this.dialog.open(DialogoPropiedad, {
      width: '70%',
      data: {tbl: tabla, idP: id, tit: titulo, btn: boton}
    })
  }

  ngAfterViewInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsConditionalByOrderDesc('t_propiedad', 'estado', '==', true, 'fecha_crea').subscribe((propiedades: any) => {
      let cantP = 1;

      if (propiedades.length > 0) {
        propiedades.forEach((propiedad: any) => {
          this.generalService.scAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'estado', '==', true).subscribe((fotos: any) => {
            this.generalService.getAllDocumentsConditional3('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'estado', '==', true, 'principal', '==', true).subscribe((fotoP: any) => {
              this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', propiedad.id_tipo_prop).subscribe((tipoP: any) => {
                propiedad.fotos = fotos.length;
                propiedad.fotoP = fotoP.length;
                propiedad.urlF = '';

                if (propiedad.estado === true && propiedad.publicar === true) {
                  propiedad.urlF = this.dom.bypassSecurityTrustResourceUrl('https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fck.colladokunstmann.cl%2Fpropiedades%2F' + propiedad.id_propiedad + '%2F' + propiedad.id_propiedad + '.html&layout=button&size=small&appId=250383808704265&width=89&height=20');
                }

                if (fotoP.length > 0) {
                  propiedad.principal = fotoP[0].url;
                }
                propiedad.tipo_prop = tipoP[0].tipo_prop;
                propiedad.tituloOrd = tipoP[0].tipo_prop + ' - ' + propiedad.sector + ' - ' + propiedad.ciudad;
                propiedad.colorD = 'red';
  
                if (propiedad.destacada_num > -1) {
                  propiedad.colorD = 'green';
                }

                if (propiedades.length === cantP) {
                  this.dataSource = new MatTableDataSource<eleProp>(propiedades);
                  this.dataSource.paginator = this.paginator;
                  localStorage.setItem('load', 'N');
                }
                cantP++;
              });
            });
          });
        });
      } else {
        this.dataSource = new MatTableDataSource<eleProp>(propiedades);
        this.dataSource.paginator = this.paginator;
        localStorage.setItem('load', 'N');
      }
    });
  }

  buscar() {
    let estProp = true;

    localStorage.setItem('load', 'S');

    if (this.estadoP === 'H') {
      estProp = true;
    } else {
      estProp = false;
    }

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsConditionalByOrderDesc('t_propiedad', 'estado', '==', estProp, 'fecha_crea').subscribe((propiedades: any) => {
      let cantP = 1;

      if (propiedades.length > 0) {
        propiedades.forEach((propiedad: any) => {
          this.generalService.scAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'estado', '==', true).subscribe((fotos: any) => {
            this.generalService.getAllDocumentsConditional3('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'estado', '==', true, 'principal', '==', true).subscribe((fotoP: any) => {
              this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', propiedad.id_tipo_prop).subscribe((tipoP: any) => {
                propiedad.fotos = fotos.length;
                propiedad.fotoP = fotoP.length;
                propiedad.urlF = '';

                if (propiedad.estado === true && propiedad.publicar === true) {
                  propiedad.urlF = this.dom.bypassSecurityTrustResourceUrl('https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fck.colladokunstmann.cl%2Fpropiedades%2F' + propiedad.id_propiedad + '%2F' + propiedad.id_propiedad + '.html&layout=button&size=small&appId=250383808704265&width=89&height=20');
                }

                if (fotoP.length > 0) {
                  propiedad.principal = fotoP[0].url;
                }
                propiedad.tipo_prop = tipoP[0].tipo_prop;
                propiedad.tituloOrd = tipoP[0].tipo_prop + ' - ' + propiedad.sector + ' - ' + propiedad.ciudad;
                propiedad.colorD = 'red';
  
                if (propiedad.destacada_num > -1) {
                  propiedad.colorD = 'green';
                }

                if (propiedades.length === cantP) {
                  this.dataSource = new MatTableDataSource<eleProp>(propiedades);
                  this.dataSource.paginator = this.paginator;
                  localStorage.setItem('load', 'N');
                }
                cantP++;
              });
            });
          });
        });
      } else {
        this.dataSource = new MatTableDataSource<eleProp>(propiedades);
        this.dataSource.paginator = this.paginator;
        localStorage.setItem('load', 'N');
      }
    });
  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const objP = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_propiedad', id, objP).then(success => { });
  }

  publicar(id, evento) {
    // ***** Editar estado *****
    const objP = {
      publicar: evento.checked
    };
    this.generalService.updateDocument('t_propiedad', id, objP).then(success => { });
  }

  destacar(id, evento) {
    // ***** Editar estado *****
    const objP = {
      destacada: evento.checked
    };
    this.generalService.updateDocument('t_propiedad', id, objP).then(success => { });
  }

  vendArr(id, evento) {
    // ***** Editar estado *****
    const objP = {
      vend_arr: evento.checked
    };
    this.generalService.updateDocument('t_propiedad', id, objP).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
  styleUrls: ['./propiedad.component.scss']
})
export class DialogoPropiedad {
  propiedad = {
    bagno: 0,
    bodega: 0,
    ciudad: '',
    coorX: 0,
    coorY: 0,
    destacada: false,
    destacada_num: -1,
    detalle: '',
    direccion: '',
    dormitorio: 0,
    estacionamiento: 0,
    estado: true,
    estado_prop: '',
    fecha_crea: new Date().getTime(),
    id_comuna: '950VInNiMyXhTJ8juTDh',
    id_moneda: 'sUhITZwmL71WUACtT2a0',
    id_pais: 'rwe2O4C5mul4BameznqN',
    id_propiedad: '',
    id_proveedor: '',
    id_region: 'qPhRIxgsh233YZMfV13S',
    id_tipo_prop: '',
    mt_construido: 0,
    mt_total: 0,
    negocio: '',
    oferta: 0,
    orden: 0,
    precio: 0,
    propietario: '',
    publicar: false,
    sector: '',
    titulo: ''
  };
  propiedades = [{id: '', idN: '', imagen: '../../../assets/img/foto1.png'},
                 {id: '', idN: '', imagen: '../../../assets/img/foto2.png'},
                 {id: '', idN: '', imagen: '../../../assets/img/foto3.png'},
                 {id: '', idN: '', imagen: '../../../assets/img/foto4.png'},
                 {id: '', idN: '', imagen: '../../../assets/img/foto5.png'},
                 {id: '', idN: '', imagen: '../../../assets/img/foto6.png'},
                 {id: '', idN: '', imagen: '../../../assets/img/foto7.png'}];
  cuadricula = '';
  paises;
  regiones;
  comunas;
  sectores;
  tipos;
  monedas;
  imagenes = [];
  existeP = false;
  urlProp = '';

  public respuestaImagenEnviada;
  public resultadoCarga;

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoPropiedad>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo,
              private storageservice: StorageService) {
    if (dialog.tbl === 'CP' || dialog.tbl === 'EP') {
      if (dialog.tbl === 'CP') {
        this.generalService.getAllDocumentsConditional('t_pais', 'estado', '==', true).subscribe((paises: any) => {
          this.paises = paises;
        });
      }
  
      this.generalService.getAllDocumentsConditional('t_tipo_prop', 'estado', '==', true).subscribe((tipos: any) => {
        this.tipos = tipos
      });
  
      this.generalService.getAllDocumentsConditional('t_moneda', 'estado', '==', true).subscribe((monedas: any) => {
        this.monedas = monedas;
      });
  
      if (this.dialog.tbl === 'EP') {
        this.generalService.getAllDocumentsConditional('t_pais', 'estado', '==', true).subscribe((paises: any) => {
          this.generalService.getAllDocumentsConditional('t_propiedad', 'id_propiedad', '==', dialog.idP).subscribe((propiedad: any) => {
            this.generalService.getAllDocumentsConditional2('t_region', 'id_pais', '==', propiedad[0].id_pais, 'estado', '==', true).subscribe((regiones: any) => {
              this.generalService.getAllDocumentsConditional2('t_comuna', 'id_region', '==', propiedad[0].id_region, 'estado', '==', true).subscribe((comunas: any) => {
                this.paises = paises;
                this.regiones = regiones;
                this.comunas = comunas;
              });
            });
          });
        });
      } else {
        this.cargarReg();
        this.cargarCom();
      }
    } 
    
    if (dialog.tbl === 'CIP') {
      this.generalService.scAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', this.dialog.idP, 'estado', '==', true).subscribe((imagenes: any) => {
        let num = 1;
        let numF = 1;
        let objI = {
          idImg1: '',
          imagen1: '',
          prin1: false,
          idImg2: '',
          imagen2: '',
          prin2: false,
          idImg3: '',
          imagen3: '',
          prin3: false,
          idImg4: '',
          imagen4: '',
          prin4: false
        };
        this.existeP = false;
        this.imagenes = [];

        imagenes.forEach((imagen: any) => {
          switch (num) {
            case 1:
              objI = {
                idImg1: '',
                imagen1: '',
                prin1: false,
                idImg2: '',
                imagen2: '',
                prin2: false,
                idImg3: '',
                imagen3: '',
                prin3: false,
                idImg4: '',
                imagen4: '',
                prin4: false
              };
              objI.idImg1 = imagen.id_propiedad_img;
              objI.imagen1 = imagen.url;
              objI.prin1 = imagen.principal;

              if (numF === imagenes.length) {
                this.imagenes.push(objI);
                num = 0;
              }
              break;
            case 2:
              objI.idImg2 = imagen.id_propiedad_img;
              objI.imagen2 = imagen.url;
              objI.prin2 = imagen.principal;

              if (numF === imagenes.length) {
                this.imagenes.push(objI);
                num = 0;
              }
              break;
            case 3:
              objI.idImg3 = imagen.id_propiedad_img;
              objI.imagen3 = imagen.url;
              objI.prin3 = imagen.principal;

              if (numF === imagenes.length) {
                this.imagenes.push(objI);
                num = 0;
              }
              break;
            case 4:
              objI.idImg4 = imagen.id_propiedad_img;
              objI.imagen4 = imagen.url;
              objI.prin4 = imagen.principal;
              this.imagenes.push(objI);
              num = 0;
              break;
          }

          if (imagen.principal === true) {
            this.existeP = true;
          }
          num++;
          numF++;
        });
      });
    }

    if (dialog.tbl === 'DP') {
      this.generalService.getAllDocumentsConditional('t_propiedad', 'id_propiedad', '==', dialog.idP).subscribe((infoProp: any) => {
        this.generalService.getAllDocumentsConditional3('t_propiedad_img', 'id_propiedad', '==', dialog.idP, 'estado', '==', true, 'principal', '==', true).subscribe((principal: any) => {
          this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', infoProp[0].id_moneda).subscribe((moneda: any) => {
            this.propiedad = infoProp[0];
            this.propiedad.id_moneda = moneda[0].moneda;
            this.urlProp = principal[0].url;
            this.cuadricula = '' + this.propiedad.destacada_num;
          });
        });
      });

      this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'estado', '==', true, 'principal', '==', true).subscribe((destacadas: any) => {
        destacadas.forEach((destacada: any) => {
          this.generalService.getAllDocumentsConditional5('t_propiedad', 'id_propiedad', '==', destacada.id_propiedad, 'estado', '==', true, 'publicar', '==', true, 'destacada', '==', true, 'destacada_num', '>', -1).subscribe((propiedad: any) => {
            if (propiedad.length > 0) {
              this.propiedades[propiedad[0].destacada_num].id = propiedad[0].id_propiedad;
              this.propiedades[propiedad[0].destacada_num].imagen = destacada.url;
            }
          });
        });
      });
    }

    if (dialog.tbl === 'OP') {
      this.generalService.getAllDocumentsConditional('t_propiedad', 'id_propiedad', '==', dialog.idP).subscribe((infoProp: any) => {
        this.propiedad = infoProp[0];
      });
    }
  }

  ngOnInit() {
    if (this.dialog.tbl === 'EP') {
      this.generalService.getAllDocumentsConditional('t_propiedad', 'id_propiedad', '==', this.dialog.idP).subscribe((propiedad: any) => {
        this.propiedad = propiedad[0];
      });
    }
  }

  public cargandoImagen(files: FileList, idPropiedad) {
    let i = 0;

    while (i < files.length) {
      this.storageservice.cargarWeb('P', idPropiedad, i, files[i]).subscribe(
        response => {
          this.respuestaImagenEnviada = response; 
          if (this.respuestaImagenEnviada <= 1) {
            console.log("Error en el servidor"); 
          } else {
            if (this.respuestaImagenEnviada.code == 200 && this.respuestaImagenEnviada.status == "success") {
              this.resultadoCarga = 1;
              const objI = {
                estado: true,
                fecha_crea: new Date().getTime(),
                id_propiedad: idPropiedad,
                id_propiedad_img: '',
                principal: false,
                texto: '',
                url: 'https://ck.colladokunstmann.cl/' + this.respuestaImagenEnviada.msj
              };
              this.generalService.newDocument(objI, 't_propiedad_img').then(successI => {
                const update = {
                  id_propiedad_img: successI.id
                };
                this.generalService.updateDocument('t_propiedad_img', successI.id, update).then(successU2 => {
                  this.openSnackBar('La imagen se ha subido correctamente.', 'Galería');
                  // this.dialogo.close();
                });
              });
            } else {
              this.resultadoCarga = 2;
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
      i++;
    }
  }

  borrar(idImg) {
    const update = {
      estado: false
    };
    this.generalService.updateDocument('t_propiedad_img', idImg, update).then(successU2 => { });
  }

  principal(idImg, respuesta) {
    if (respuesta === 'S') {
      const update = {
        principal: true
      };
      this.generalService.updateDocument('t_propiedad_img', idImg, update).then(successU2 => { });
    } else {
      const update = {
        principal: false
      };
      this.generalService.updateDocument('t_propiedad_img', idImg, update).then(successU2 => { });
    }
  }

  cargarReg() {
    this.generalService.getAllDocumentsConditional2('t_region', 'id_pais', '==', this.propiedad.id_pais, 'estado', '==', true).subscribe((regiones: any) => {
      this.regiones = [];
      this.regiones = regiones;
    });
  }

  cargarCom() {
    this.generalService.getAllDocumentsConditional2('t_comuna', 'id_region', '==', this.propiedad.id_region, 'estado', '==', true).subscribe((comunas: any) => {
      this.comunas = [];
      this.comunas = comunas;
    });
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    switch (this.dialog.tbl) {
      case 'CP':
        if (this.propiedad.negocio !== '' && this.propiedad.estado_prop !== '' && this.propiedad.id_pais !== '' &&
            this.propiedad.id_region !== '' && this.propiedad.id_comuna !== '' && this.propiedad.sector !== '' &&
            this.propiedad.ciudad !== '' && this.propiedad.id_tipo_prop !== '') {
          this.generalService.newDocument(this.propiedad, 't_propiedad').then(successI => {
            const update = {
              id_propiedad: successI.id
            };
            this.generalService.updateDocument('t_propiedad', successI.id, update).then(successU2 => {
              this.openSnackBar('La propiedad se ha creado correctamente.', 'Nueva Propiedad');
              this.dialogo.close();
            });
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Nueva Propiedad');
        }
        break;
      case 'EP':
        if (this.propiedad.negocio !== '' && this.propiedad.estado_prop !== '' && this.propiedad.id_pais !== '' &&
            this.propiedad.id_region !== '' && this.propiedad.id_comuna !== '' && this.propiedad.sector !== '' &&
            this.propiedad.ciudad !== '' && this.propiedad.id_tipo_prop !== '' && this.propiedad.id_moneda !== '') {
          this.generalService.updateDocument('t_propiedad', this.propiedad.id_propiedad, this.propiedad).then(successU2 => {
            this.openSnackBar('La propiedad se ha editado correctamente.', 'Editar Propiedad');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Editar Propiedad');
        }
        break;
    }
  }

  guardarC() {
    if (this.cuadricula === '-1') {
      const objP = {
        destacada_num: -1
      };
      this.generalService.updateDocument('t_propiedad', this.dialog.idP, objP).then(success => {
        this.openSnackBar('La propiedad quedó sin cuadrícula asignada.', 'Destacada');
        this.dialogo.close();
      });
    } else {
      const objP = {
        destacada_num: +this.cuadricula
      };
      this.generalService.updateDocument('t_propiedad', this.dialog.idP, objP).then(success => {
        if (this.propiedades[this.cuadricula].id !== '') {
          const objP2 = {
            destacada_num: -1
          };
          this.generalService.updateDocument('t_propiedad', this.propiedades[this.cuadricula].id, objP2).then(success => {
            this.openSnackBar('La propiedad quedó asignada a la cuadrícula seleccionada.', 'Destacada');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('La propiedad quedó asignada a la cuadrícula seleccionada.', 'Destacada');
          this.dialogo.close();
        }
      });
    }
  }

  guardarO() {
    let orden = 0;

    if (this.propiedad.orden !== null) {
      orden = this.propiedad.orden;
    }

    const objP = {
      orden: orden
    };
    this.generalService.updateDocument('t_propiedad', this.dialog.idP, objP).then(success => {
      this.openSnackBar('Número orden actualizado.', 'Orden');
      this.dialogo.close();
    });
  }

  mostrarC() {
    this.propiedades = [{id: '', idN: '', imagen: '../../../assets/img/foto1.png'},
                        {id: '', idN: '', imagen: '../../../assets/img/foto2.png'},
                        {id: '', idN: '', imagen: '../../../assets/img/foto3.png'},
                        {id: '', idN: '', imagen: '../../../assets/img/foto4.png'},
                        {id: '', idN: '', imagen: '../../../assets/img/foto5.png'},
                        {id: '', idN: '', imagen: '../../../assets/img/foto6.png'},
                        {id: '', idN: '', imagen: '../../../assets/img/foto7.png'}];
    
    this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'estado', '==', true, 'principal', '==', true).subscribe((destacadas: any) => {
      let cant = 1;

      destacadas.forEach((destacada: any) => {
        this.generalService.getAllDocumentsConditional5('t_propiedad', 'id_propiedad', '==', destacada.id_propiedad, 'estado', '==', true, 'publicar', '==', true, 'destacada', '==', true, 'destacada_num', '>', -1).subscribe((propiedad: any) => {
          if (propiedad.length > 0) {
            this.propiedades[propiedad[0].destacada_num].id = propiedad[0].id_propiedad;
            this.propiedades[propiedad[0].destacada_num].imagen = destacada.url;
          }

          if (cant === destacadas.length) {
            if (this.cuadricula !== '-1') {
              this.propiedades[+this.cuadricula].idN = this.propiedad.id_propiedad;
              this.propiedades[+this.cuadricula].imagen = this.urlProp;
            }
        
            if (this.propiedad.destacada_num > -1) {
              this.propiedades[this.propiedad.destacada_num].idN = 'VACIO';
        
              switch (this.propiedad.destacada_num) {
                case 0:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto1.png';
                  break;
                case 1:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto2.png';
                  break;
                case 2:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto3.png';
                  break;
                case 3:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto4.png';
                  break;
                case 4:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto5.png';
                  break;
                case 5:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto6.png';
                  break;
                case 6:
                  this.propiedades[this.propiedad.destacada_num].imagen = '../../../assets/img/foto7.png';
                  break;
              }
            }
          }
          cant++;
        });
      });
    });
  }
}
