import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { GeneralService } from '../../servicio/general.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  tbl: string;
  idC: string;
}

export interface eleConsulta {
  asunto: string;
  email: string;
  estado: string;
  fecha_crea: number;
  id_consulta: string;
  mensaje: string;
  nombre: string;
  telefono: string;
  tipo: string;
}

export interface eleSeguimiento {
  estado: string;
  fecha_crea: number;
  id_consulta: string;
  id_consulta_seg: string;
  id_usuario: string;
  observacion: string;
}

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class ConsultaComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['fecha', 'tipo', 'asunto', 'nombre', 'telefono', 'estado', 'accion'];
  agnoAct = new Date().getFullYear();
  mesAct = new Date().getMonth() + 1;
  agnos = [];
  meses = [{num: 1, mes: 'Enero'}, {num: 2, mes: 'Febrero'}, {num: 3, mes: 'Marzo'}, {num: 4, mes: 'Abril'},
           {num: 5, mes: 'Mayo'}, {num: 6, mes: 'Junio'}, {num: 7, mes: 'Julio'}, {num: 8, mes: 'Agosto'},
           {num: 9, mes: 'Septiembre'}, {num: 10, mes: 'Octubre'}, {num: 11, mes: 'Noviembre'}, {num: 12, mes: 'Diciembre'}];
           
  constructor(private generalService: GeneralService, private router: Router, private dashboard: DashboardComponent,
              public authenticationService: AuthenticationService, public dialog: MatDialog) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(tabla, id) {
    this.dialog.open(DialogoConsulta, {
      width: '80%',
      data: {tbl: tabla, idC: id}
    })
  }

  ngAfterViewInit(): void {
    let cantA = 2021;

    while (cantA < this.agnoAct) {
      this.agnos.push({agno: cantA});
      cantA++;
    }
    this.agnos.push({agno: this.agnoAct});
    this.buscar();
  }

  buscar() {
    localStorage.setItem('load', 'S');

    let fechaI;
    let fechaF;
    let mesA = +this.mesAct;
    const ultDia = new Date(this.agnoAct, mesA, 0).getDate();

    if (localStorage.getItem('navegador') === 'Apple Computer, Inc.') {
      if (mesA < 10) {
        fechaI = new Date(this.agnoAct + '-0' + mesA + '-01' + 'T00:00:00').getTime();
        fechaF = new Date(this.agnoAct + '-0' + mesA + '-' + ultDia + 'T23:59:59').getTime();
      } else {
        fechaI = new Date(this.agnoAct + '-' + mesA + '-01' + 'T00:00:00').getTime();
        fechaF = new Date(this.agnoAct + '-' + mesA + '-' + ultDia + 'T23:59:59').getTime();
      }
    } else {
      fechaI = new Date(this.agnoAct + '-' + mesA + '-01' + ' 00:00:00').getTime();
      fechaF = new Date(this.agnoAct + '-' + mesA + '-' + ultDia + ' 23:59:59').getTime();
    }

    this.generalService.scAllDocumentsConditional2ByOrderDesc('t_consulta', 'fecha_crea', '>=', fechaI, 'fecha_crea', '<=', fechaF, 'fecha_crea').subscribe((consultas: any) => {
      if (consultas.length > 0) {
        consultas.forEach((consulta: any) => {
          switch (consulta.tipo) {
            case 'C':
              consulta.tipo = 'Contacto Web';
              break;
            case 'F':
              consulta.tipo = 'Facebook';
              break;
            case 'I':
              consulta.tipo = 'Portal Inmob.';
              break;
            case 'O':
              consulta.tipo = 'Oficina';
              break;
            case 'P':
              consulta.tipo = 'Propiedad Web';
              break;
            case 'T':
              consulta.tipo = 'Teléfono';
              break;
          }
  
          switch (consulta.estado) {
            case 'N':
              consulta.estado = 'No leida';
              break;
            case 'R':
              consulta.estado = 'Recibida';
              break;
            case 'G':
              consulta.estado = 'Gestionando';
              break;
            case 'F':
              consulta.estado = 'Finalizada';
              break;
          }
        });
        //this.dataSource = new MatTableDataSource(consultas);
        this.dataSource = new MatTableDataSource<eleConsulta>(consultas);
        this.dataSource.paginator = this.paginator;
        localStorage.setItem('load', 'N');
      } else {
        this.dataSource = new MatTableDataSource<eleConsulta>(consultas);
        this.dataSource.paginator = this.paginator;
        localStorage.setItem('load', 'N');
      }
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class DialogoConsulta {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['fecha', 'observacion', 'estado'];
  txtFecha = 0;
  consulta = {
    asunto: '',
    email: '',
    estado: '',
    fecha_crea: new Date().getTime(),
    id_consulta: '',
    mensaje: '',
    nombre: '',
    telefono: '',
    tipo: ''
  };
  seguimiento = {
    estado: '',
    fecha_crea: new Date().getTime(),
    id_consulta: '',
    id_consulta_seg: '',
    id_usuario: localStorage.getItem('id_usuario'),
    observacion: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoConsulta>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (this.dialog.tbl === 'VI') {
      this.generalService.getAllDocumentsConditional('t_consulta', 'id_consulta', '==', this.dialog.idC).subscribe((consulta: any) => {
        if (consulta[0].tipo === 'C') {
          consulta[0].tipo = 'Contacto Web';
        } else {
          consulta[0].tipo = 'Consulta Propiedad';
        }
        this.txtFecha = consulta[0].fecha_crea;

        switch (consulta[0].estado) {
          case 'N':
            consulta[0].estado = 'No leida';
            this.seguimiento.id_consulta = this.dialog.idC;
            this.seguimiento.estado = 'R';
            this.seguimiento.observacion = 'La consulta fue leida';
            this.generalService.newDocument(this.seguimiento, 't_consulta_seg').then(successI => {
              const update = {
                id_consulta_seg: successI.id
              };
              this.generalService.updateDocument('t_consulta_seg', successI.id, update).then(successU => {
                const update2 = {
                  estado: 'R'
                };
                this.generalService.updateDocument('t_consulta', this.dialog.idC, update2).then(successU2 => {})
              });
            });
            break;
          case 'R':
            consulta[0].estado = 'Recibida';
            break;
          case 'G':
            consulta[0].estado = 'Gestionando';
            break;
          case 'F':
            consulta[0].estado = 'Finalizada';
            break;
        }
        this.consulta = consulta[0];
      });

      this.generalService.getAllDocumentsConditionalByOrder('t_consulta_seg', 'id_consulta', '==', this.dialog.idC, 'fecha_crea').subscribe((seguimientos: any) => {
        if (seguimientos.length > 0) {
          seguimientos.forEach((seguimiento: any) => {
            switch (seguimiento.estado) {
              case 'N':
                seguimiento.estado = 'No leida';
                break;
              case 'R':
                seguimiento.estado = 'Recibida';
                break;
              case 'G':
                seguimiento.estado = 'Gestionando';
                break;
              case 'F':
                seguimiento.estado = 'Finalizada';
                break;
            }
          });
          //this.dataSource = new MatTableDataSource(seguimientos);
          this.dataSource = new MatTableDataSource<eleSeguimiento>(seguimientos);
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource = new MatTableDataSource<eleSeguimiento>(seguimientos);
          this.dataSource.paginator = this.paginator;
        }
      });
    }

    if (this.dialog.tbl === 'AI') {
      this.seguimiento.id_consulta = this.dialog.idC;
    }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  ingresar() {
    this.consulta.id_consulta = this.generalService.newId();
    this.consulta.estado = 'R';

    this.generalService.newDocumentWithId(this.consulta, 't_consulta', this.consulta.id_consulta).then(successI => {
      this.seguimiento.id_consulta_seg = this.generalService.newId();
      this.seguimiento.id_consulta = this.consulta.id_consulta;
      this.seguimiento.estado = 'R';
      this.seguimiento.observacion = 'La consulta fue ingresada';

      this.generalService.newDocumentWithId(this.seguimiento, 't_consulta_seg', this.consulta.id_consulta).then(successI2 => {
        this.openSnackBar('La consulta se ha registrado correctamente.', 'Ingreso consulta');
        this.dialogo.close();
      });
    });
  }

  actualizar() {
    this.generalService.newDocument(this.seguimiento, 't_consulta_seg').then(successI => {
      const update = {
        id_consulta_seg: successI.id
      };
      this.generalService.updateDocument('t_consulta_seg', successI.id, update).then(successU => {
        const update2 = {
          estado: this.seguimiento.estado
        };
        this.generalService.updateDocument('t_consulta', this.seguimiento.id_consulta, update2).then(successU2 => {
          this.openSnackBar('El seguimiento se ha registrado correctamente.', 'Seguimiento consulta');
          this.dialogo.close();
        });
      });
    });
  }
}
