import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../servicio/general.service';
import { AuthenticationService } from '../../servicio/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tipoProp;
  comuna;
  destacadas;
  buscarURL = '';
  buscarD = {
    negocio: '',
    tipo: '',
    comuna: ''
  };
  propiedades: any = [];

  constructor(private generalService: GeneralService, public authenticationService: AuthenticationService) {
    this.generalService.getAllDocumentsConditionalByOrder('t_tipo_prop', 'estado', '==', true, 'tipo_prop').subscribe(tipoProp => {
      this.tipoProp = [];
      this.tipoProp = tipoProp;
    });

    this.generalService.getAllDocumentsConditionalByOrder('t_comuna', 'estado', '==', true, 'comuna').subscribe(comuna => {
      this.comuna = [];
      this.comuna = comuna;
    });
  }

  ngOnInit(): void {
    this.generalService.scAllDocumentsConditional4('t_propiedad', 'estado', '==', true, 'publicar', '==', true, 'destacada', '==', true, 'destacada_num', '>', -1).subscribe((destacadas: any) => {
      if (destacadas.length > 0) {
        destacadas.forEach((destacada: any) => {
          this.generalService.getAllDocumentsConditional3('t_propiedad_img', 'id_propiedad', '==', destacada.id_propiedad, 'estado', '==', true, 'principal', '==', true).subscribe((imagen: any) => {
            this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', destacada.id_moneda).subscribe((moneda: any) => {
              this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', destacada.id_tipo_prop).subscribe((tipoProp: any) => {
                this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', destacada.id_comuna).subscribe((comuna: any) => {
                  if (destacada.negocio === 'A') {
                    this.propiedades.push({id: destacada.id_propiedad, titulo: destacada.titulo, moneda: moneda[0].moneda, precio: destacada.precio, imagen: imagen[0].url, vendArr: 'Arriendo', tipo: tipoProp[0].tipo_prop, url: destacada.url, comuna: comuna[0].comuna});
                  } else {
                    this.propiedades.push({id: destacada.id_propiedad, titulo: destacada.titulo, moneda: moneda[0].moneda, precio: destacada.precio, imagen: imagen[0].url, vendArr: 'Venta', tipo: tipoProp[0].tipo_prop, url: destacada.url, comuna: comuna[0].comuna});
                  }
                });
              });
            });
          });
        });
      }
    });
  }
}
