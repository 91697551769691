<div class="container-fluid text-md-center" style="padding-bottom: 60px; background-color: #F3F5F5; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
    <div class="row" style="width: 60%; margin-left: 20%; padding-top: 40px; font-size: 2.2vw; text-align: left;">
        <div class="col-lg-5">En <strong>Collado + Kunstmann</strong> te <strong>ofrecemos</strong> los siguientes servicios:</div>
        <div class="col-lg-7"><img class="img-fluid" src="../../../assets/img/servicios.jpg" alt="" style="height: auto; width: 100%;" /></div>
    </div>
    <div class="row" style="width: 60%; margin-left: 20%; padding-top: 40px; padding-bottom: 40px;">
        <div class="col-lg-6">
            <div class="row" style="padding-bottom: 40px;"><div class="col-lg-12"><strong>Regulación de construcciones:</strong><br><br>Tramitación ante la dirección de obras de las respectivas municipalidades Permiso de edificación y recepción final, planos digitales, documentos y aprobaciones</div></div>
            <div class="row"><div class="col-lg-12"><strong>Corretaje de Propiedades:</strong><br><br>Ya sea para arriendo o venta, Asesoría en toma de decisiones</div></div>
        </div>
        <div class="col-lg-6">
            <div class="row" style="padding-bottom: 40px;"><div class="col-lg-12"><strong>Inspección técnica de obras:</strong><br><br>Proyectos habitacionales ya sea urbanos o rurales</div></div>
            <div class="row"><div class="col-lg-12"><strong>Levantamiento Topográfico:</strong><br><br>Loteos en general, planos y estudios de ingeniería</div></div>
        </div>
    </div>
</div>
<app-pie></app-pie>
