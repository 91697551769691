<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
  <!-- ********** Inicio dialogo creación/edición ********** -->
  <div *ngIf="dialog.dlg === 'CU' || dialog.dlg === 'EU'">
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 20%;">
          <span class="input-group-text" id="txtEmail" style="background-color: white; border: none;">Mail</span>
        </div>
        <input *ngIf="dialog.dlg === 'CU'" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtEmail" [(ngModel)]="usuario.email">
        <div *ngIf="dialog.dlg === 'EU'">{{usuario.email}}</div>
    </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 20%;">
          <span class="input-group-text" id="cbPerfil" style="background-color: white; border: none;">Perfil</span>
        </div>
        <select class="custom-select" id="cbPerfil" placeholder="Seleccionar" [(ngModel)]="usuario.id_perfil">
            <option value="" selected>Seleccionar</option>
            <option *ngFor="let perfil of perfiles" [value]="perfil.id_perfil">{{perfil.perfil}}</option>
        </select>
      </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 20%;">
            <span class="input-group-text" id="txtNombre" style="background-color: white; border: none;">Nombres</span>
        </div>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtNombre" placeholder="Primer nombre" [(ngModel)]="usuario.nombre_01">
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtNombre" placeholder="Segundo nombre (Opcional)" [(ngModel)]="usuario.nombre_02">
    </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 20%;">
          <span class="input-group-text" id="txtNombre" style="background-color: white; border: none;">Apellidos</span>
        </div>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtApellido" placeholder="Primer apellido" [(ngModel)]="usuario.apellido_01">
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtApellido" placeholder="Segundo apellido (Opcional)" [(ngModel)]="usuario.apellido_02">
    </div>
  </div>
  <!-- ********** Fin dialogo creación/edición ********** -->
</div>
<div mat-dialog-actions>
  <button *ngIf="dialog.dlg === 'CU'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="crear()">Crear Usuario</button>
  <button *ngIf="dialog.dlg === 'EU'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="editar()">Editar Usuario</button>
</div>
