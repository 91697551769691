import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../servicio/authentication.service';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {
  constructor(public authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }
}
