<div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-caption d-none d-md-block" style="top: 0%; margin-left: -15%; width: 100%; height: 70vh; color: white; background-color: rgba(66, 63, 63, 0.507); font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
      <div style="width: 60%; margin-left: 20%; margin-top: 4%; text-align: left;">
        <p style="font-size: 4.2vw; line-height: 4.2vw;">El Servicio</p>
        <p style="font-size: 2.2vw; line-height: 2.2vw;">que buscas para satisfacer</p>
        <p style="font-size: 2.2vw; line-height: 2.2vw;">tus necesidades.</p>
        <p>Encuéntranos en el Sur de Chile</p>
        <p><button type="button" class="btn btn-primary" routerLink="propiedades">VER PROPIEDADES</button></p>
      </div>
    </div>
    <div class="carousel-item active">
      <img src="../../../assets/img/valdivia1.jpg" style="width: 100%; height: 70vh;" class="d-block" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../assets/img/valdivia2.jpeg" style="width: 100%; height: 70vh;" class="d-block" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../assets/img/valdivia3.jpg" style="width: 100%; height: 70vh;" class="d-block" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div class="row" style="margin-top: 40px; margin-bottom: 40px; width: 70%; margin-left: 15%;">
  <div class="col-lg-6"><img src="../../../assets/img/imagen1.jpg" style="width: 100%; height: auto;"></div>
  <div class="col-lg-6" style="text-align: right; font-family: Roboto;">
    <p style="font-size: 20px; font-weight: bold;">Confianza garantizada</p>
    <p style="font-size: 4.2vw; line-height: 4.2vw;">¿Tienes una propiedad para Vender o Arrendar?</p>
    <br><button type="button" class="btn btn-primary" routerLink="servicios">Más Información</button>
  </div>
</div>
<p style="width: 100%; text-align:center; font-size: 40px; font-weight: bold; font-family: Roboto;">Propiedades Destacadas</p>
<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" style="width: 70%; margin-left: 15%;">
  <div *ngFor="let prop of propiedades" class="col">
    <div class="shadow-sm" style="cursor: pointer;" [routerLink]="['/detalle', prop.url]">
      <img src="{{prop.imagen}}" style="width: 100%; height: 300px;">
      <div class="card-body">
        <p class="card-text" style="font-size: 15px; line-height: 15px;">{{prop.comuna}} | {{prop.tipo}}</p>
        <p class="card-text" style="font-size: 20px; line-height: 20px; font-weight: bold;">{{prop.vendArr}} | {{prop.titulo}}</p>
        <p class="card-text">{{prop.moneda}} {{prop.precio | number}}</p>
      </div>
    </div>
  </div>
</div>
<app-pie></app-pie>
