import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../servicio/authentication.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {
  constructor(public authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }
}
