<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<div *ngIf="dialog.tbl === 'Contacto'" class="container" style="background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url('../../assets/img/sobre.png');
background-repeat: no-repeat; background-position: center center; min-height: 400px;">
  <section>
    <div class="forms">
      <h5 class="text-center" style="color: #3576AB; font-weight: bold; padding-top: 30px;">Formulario de Contacto</h5>
      <div style="text-align: center; margin-bottom: 20px;">{{dialog.cdg + ' - ' + dialog.tipo + ' - ' + dialog.mon + ' ' + dialog.precio}}</div>
      <form (ngSubmit)="onSubmit(contactForm, 'Contacto');" #contactForm="ngForm">
        <mat-form-field style="width: 60%;">
          <input matInput placeholder="Nombre" #nombre maxlength="80" [(ngModel)]="ContactModel.dato3" name="Name" required>
          <mat-hint align="end">{{nombre.value?.length || 0}}/80</mat-hint>
        </mat-form-field>
        <mat-form-field style="width: 35%; margin-left: 5%;">
          <input matInput placeholder="Teléfono" #tel type="tel" [(ngModel)]="ContactModel.dato5" name="Phone" maxlength="16">
          <mat-hint align="end">{{tel.value?.length || 0}}/16</mat-hint>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="Email" type="email" [(ngModel)]="ContactModel.dato4" name="Email" [email]="true" required>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
          <textarea matInput placeholder="Mensaje" #msn maxlength="2000" [(ngModel)]="ContactModel.dato7" name="Msn" cols="25" required></textarea>
          <mat-hint align="end">{{msn.value?.length || 0}}/2000</mat-hint>
        </mat-form-field>
        <div *ngIf="!verBoton">Enviando mail...</div>
        <button *ngIf="verBoton" mat-raised-button color="primary" [disabled]="!contactForm.form.valid">Enviar</button>
      </form>
    </div>
  </section>
</div>
<div *ngIf="dialog.tbl === 'Imagen'" class="container">
  <div style="text-align: center;">
    <img *ngIf="urlI !== ''" style="width: 100%; height: auto;" src="{{urlI}}" alt="Home">
    <mat-icon style="cursor: pointer; font-size: 50px; color: grey; float: left;" (click)="anterior()">keyboard_arrow_left</mat-icon>
    <mat-icon style="cursor: pointer; font-size: 50px; color: grey; float: right;" (click)="siguiente()">keyboard_arrow_right</mat-icon>
  </div>
</div>
