import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MailForm } from '../../servicio/mailForm';
import { SendEmailService } from '../../servicio/send-email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { GeneralService } from '../../servicio/general.service';
import { AuthenticationService } from '../../servicio/authentication.service';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new MailForm();

  constructor(private sendServices: SendEmailService, public snackBar: MatSnackBar,
              private generalService: GeneralService, public authenticationService: AuthenticationService) {
  }
  
  ngOnInit() { };
  
  getErrorMessage() {
    return this.email.hasError('required') ? 'Ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo invalido' :
        '';
  }

  onSubmit(f: NgForm, tipoF) {
    this.ContactModel.tipo = tipoF;
    this.ContactModel.dato1 = tipoF;
    this.ContactModel.dato2 = localStorage.getItem('barra');
    this.getSentServices(this.ContactModel, f);
  }

  getSentServices(body: MailForm, f: NgForm) {
    this.sendServices.getResponseEmail(body).subscribe(
      data => {
        if (data) {
          const objN = {
            asunto: this.ContactModel.dato6,
            email: this.ContactModel.dato4,
            estado: 'N',
            fecha_crea: new Date().getTime(),
            id_consulta: '',
            mensaje: this.ContactModel.dato7,
            nombre: this.ContactModel.dato3,
            telefono: this.ContactModel.dato5,
            tipo: 'C'
          }
          this.generalService.newDocument(objN, 't_consulta').then(successI => {
            const update = {
              id_consulta: successI.id
            };
            this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
              this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
                duration: 5000,
              });
            });
          });
          f.reset();
        } else {
          this.snackBar.open(':(', 'Error', {
            duration: 2000,
          });
        }
      },
      err => { /*this.snackBar.open('Algo fallo :/', 'ups', {
        duration: 5000,
      });*/
      const objN = {
        asunto: this.ContactModel.dato6,
        email: this.ContactModel.dato4,
        estado: 'N',
        fecha_crea: new Date().getTime(),
        id_consulta: '',
        mensaje: this.ContactModel.dato7,
        nombre: this.ContactModel.dato3,
        telefono: this.ContactModel.dato5,
        tipo: 'C'
      }
      this.generalService.newDocument(objN, 't_consulta').then(successI => {
        const update = {
          id_consulta: successI.id
        };
        this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
          this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
            duration: 5000,
          });
        });
      });
      f.reset();
    });
  }
}
