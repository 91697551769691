<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
  <div *ngIf="dialog.tbl === 'CP' || dialog.tbl === 'EP'"> 
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtNegocio" style="background-color: white; border: none;">Negocio *</span>
      </div>
      <select class="custom-select" id="cbNegocio" placeholder="Seleccionar" [(ngModel)]="propiedad.negocio">
        <option value="" selected>Seleccionar</option>
        <option value="A">Arriendo</option>
        <option value="V">Venta</option>
      </select>
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtEstadoP" style="background-color: white; border: none;">Estado prop. *</span>
      </div>
      <select class="custom-select" id="cbEstadoP" placeholder="Seleccionar" [(ngModel)]="propiedad.estado_prop">
        <option value="" selected>Seleccionar</option>
        <option value="N">Nueva</option>
        <option value="U">Usada</option>
      </select>
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtProp" style="background-color: white; border: none;">Propietario</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtProp" [(ngModel)]="propiedad.propietario">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtPais" style="background-color: white; border: none;">País *</span>
      </div>
      <select class="custom-select" id="cbPais" placeholder="Seleccionar" (change)="cargarReg()" [(ngModel)]="propiedad.id_pais">
        <option value="" selected>Seleccionar</option>
        <option *ngFor="let pais of paises" [value]="pais.id_pais">{{pais.pais}}</option>
      </select>
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtRegion" style="background-color: white; border: none;">Región *</span>
      </div>
      <select class="custom-select" id="cbRegion" placeholder="Seleccionar" (change)="cargarCom()" [(ngModel)]="propiedad.id_region">
        <option value="" selected>Seleccionar</option>
        <option *ngFor="let region of regiones" [value]="region.id_region">{{region.region}}</option>
      </select>
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtComuna" style="background-color: white; border: none;">Comuna *</span>
      </div>
      <select class="custom-select" id="cbComuna" placeholder="Seleccionar" [(ngModel)]="propiedad.id_comuna">
        <option value="" selected>Seleccionar</option>
        <option *ngFor="let comuna of comunas" [value]="comuna.id_comuna">{{comuna.comuna}}</option>
      </select>
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtSector" style="background-color: white; border: none;">Sector *</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="propiedad.sector">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtDireccion" style="background-color: white; border: none;">Dirección</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtDireccion" [(ngModel)]="propiedad.direccion">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtCiudad" style="background-color: white; border: none;">Ciudad *</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="propiedad.ciudad">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtCoorX" style="background-color: white; border: none;">Coordenada X</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtCoorX" [(ngModel)]="propiedad.coorX">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtCoorY" style="background-color: white; border: none;">Coordenada Y</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtCoorY" [(ngModel)]="propiedad.coorY">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtTipoP" style="background-color: white; border: none;">Tipo prop. *</span>
      </div>
      <select class="custom-select" id="cbTipoP" placeholder="Seleccionar" [(ngModel)]="propiedad.id_tipo_prop">
        <option value="" selected>Seleccionar</option>
        <option *ngFor="let tipo of tipos" [value]="tipo.id_tipo_prop">{{tipo.tipo_prop}}</option>
      </select>
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtPrecio" style="background-color: white; border: none;">Precio</span>
      </div>
      <select class="custom-select" style="width: 15%;" id="cbTipoP" placeholder="Seleccionar" [(ngModel)]="propiedad.id_moneda">
        <option *ngFor="let moneda of monedas" [value]="moneda.id_moneda">{{moneda.moneda}}</option>
      </select>
      <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtPrecio" [(ngModel)]="propiedad.precio">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtMtTotal" style="background-color: white; border: none;">Mt. Totales</span>
      </div>
      <input type="number" class="form-control" style="width: 35%;" aria-label="Sizing example input" aria-describedby="txtMtTotal" [(ngModel)]="propiedad.mt_total">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtMtConst" style="background-color: white; border: none;">Mt. Construidos</span>
      </div>
      <input type="number" class="form-control" style="width: 35%;" aria-label="Sizing example input" aria-describedby="txtMtConst" [(ngModel)]="propiedad.mt_construido">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtSector" style="background-color: white; border: none;">Dormitorios</span>
      </div>
      <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="propiedad.dormitorio">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtSector" style="background-color: white; border: none;">Baños</span>
      </div>
      <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="propiedad.bagno">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtSector" style="background-color: white; border: none;">Estacionamientos</span>
      </div>
      <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="propiedad.estacionamiento">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtSector" style="background-color: white; border: none;">Bodega</span>
      </div>
      <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="propiedad.bodega">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtTitulo" style="background-color: white; border: none;">Titulo</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtTitulo" [(ngModel)]="propiedad.titulo">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtDetalle" style="background-color: white; border: none;">Detalle</span>
      </div>
      <textarea class="form-control" rows="5" aria-label="Sizing example input" aria-describedby="txtDetalle" [(ngModel)]="propiedad.detalle"></textarea>
    </div>
  </div>
  <div *ngIf="dialog.tbl === 'CIP'">
    <div style="text-align: center;"><input type="file" style="margin-bottom: 20px;" (change)="cargandoImagen($event.target.files, dialog.idP)" multiple></div>
    <div *ngFor="let imagen of imagenes" class="row" style="margin: 0 auto; margin-bottom: 40px;">
      <div class="col-sm" *ngIf="imagen.imagen1 !== ''">
        <img style="width: 150px; height: 150px;" class="d-block" src="{{imagen.imagen1}}">
        <div style="width: 100%; text-align: center;">
          <mat-icon *ngIf="imagen.prin1" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg1, 'N')">turned_in</mat-icon>
          <mat-icon *ngIf="!existeP" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg1, 'S')">turned_in_not</mat-icon>
          <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg1)">delete</mat-icon>
        </div>
      </div>
      <div class="col-sm" *ngIf="imagen.imagen1 === ''">&nbsp;</div>
      <div class="col-sm" *ngIf="imagen.imagen2 !== ''">
        <img style="width: 150px; height: 150px;" class="d-block" src="{{imagen.imagen2}}">
        <div style="width: 100%; text-align: center;">
          <mat-icon *ngIf="imagen.prin2" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg2, 'N')">turned_in</mat-icon>
          <mat-icon *ngIf="!existeP" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg2, 'S')">turned_in_not</mat-icon>
          <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg2)">delete</mat-icon>
        </div>
      </div>
      <div class="col-sm" *ngIf="imagen.imagen2 === ''">&nbsp;</div>
      <div class="col-sm" *ngIf="imagen.imagen3 !== ''">
        <img style="width: 150px; height: 150px;" class="d-block" src="{{imagen.imagen3}}">
        <div style="width: 100%; text-align: center;">
          <mat-icon *ngIf="imagen.prin3" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg3, 'N')">turned_in</mat-icon>
          <mat-icon *ngIf="!existeP" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg3, 'S')">turned_in_not</mat-icon>
          <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg3)">delete</mat-icon>
        </div>
      </div>
      <div class="col-sm" *ngIf="imagen.imagen3 === ''">&nbsp;</div>
      <div class="col-sm" *ngIf="imagen.imagen4 !== ''">
        <img style="width: 150px; height: 150px;" class="d-block" src="{{imagen.imagen4}}">
        <div style="width: 100%; text-align: center;">
          <mat-icon *ngIf="imagen.prin4" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg4, 'N')">turned_in</mat-icon>
          <mat-icon *ngIf="!existeP" title="Principal" style="cursor: pointer;" (click)="principal(imagen.idImg4, 'S')">turned_in_not</mat-icon>
          <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg4)">delete</mat-icon>
        </div>
      </div>
      <div class="col-sm" *ngIf="imagen.imagen4 === ''">&nbsp;</div>
    </div>
  </div>
  <div *ngIf="dialog.tbl === 'DP'" [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra')}" style="padding: 15px;">
    <div class="row"><div class="col" style="color: white; text-align: center;">{{propiedad.direccion}}</div></div>
    <div class="row"><div class="col" style="color: white; text-align: center;">{{propiedad.sector}}</div></div>
    <div class="row"><div class="col" style="color: white; text-align: center;">{{propiedad.id_moneda}} {{propiedad.precio | number}}</div></div>
    <div class="row"><div class="col" style="margin-bottom: 10px;">
      <select class="custom-select" id="cbNegocio" placeholder="Seleccionar" [(ngModel)]="cuadricula" (change)="mostrarC()">
        <option value="-1" selected>Seleccionar</option>
        <option value="0">Cuadrícula 1</option>
        <option value="1">Cuadrícula 2</option>
        <option value="2">Cuadrícula 3</option>
        <option value="3">Cuadrícula 4</option>
        <option value="4">Cuadrícula 5</option>
        <option value="5">Cuadrícula 6</option>
        <option value="6">Cuadrícula 7</option>
      </select>
    </div></div>
    <div class="row no-gutters">
      <div class="col-sm-2">
        <div class="row no-gutters" style="padding: 5px; position: relative;">
          <img src="{{propiedades[0].imagen}}" style="width: 100%; height: 360px;">
          <div class="txtD" *ngIf="propiedades[0].id !== ''">
            <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 20px;">Cuadrícula 1</label>
          </div>
        </div>
        <div class="row no-gutters" style="padding: 5px; position: relative;">
          <img src="{{propiedades[1].imagen}}" style="width: 100%; height: 180px;">
          <div class="txtD" *ngIf="propiedades[1].id !== ''">
            <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 20px;">Cuadrícula 2</label>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="row no-gutters" style="padding: 5px; position: relative;">
          <img src="{{propiedades[2].imagen}}" style="width: 100%; height: 240px">
          <div class="txtD" *ngIf="propiedades[2].id !== ''">
            <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 20px;">Cuadrícula 3</label>
          </div>
        </div>
        <div class="row no-gutters" style="padding: 5px; position: relative;">
          <img src="{{propiedades[3].imagen}}" style="width: 100%; height: 300px">
          <div class="txtD" *ngIf="propiedades[3].id !== ''">
            <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 20px;">Cuadrícula 4</label>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row no-gutters">
          <div class="col-sm-5" style="padding: 5px; position: relative;">
            <img src="{{propiedades[4].imagen}}" style="width: 100%; height: 330px;">
            <div class="txtD" *ngIf="propiedades[4].id !== ''">
              <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 20px;">Cuadrícula 5</label>
            </div>
          </div>
          <div class="col-sm-7" style="padding: 5px; position: relative;">
            <img src="{{propiedades[5].imagen}}" style="width: 100%; height: 330px;">
            <div class="txtD" *ngIf="propiedades[5].id !== ''">
              <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 20px;">Cuadrícula 6</label>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-sm" style="padding: 5px; position: relative;">
            <img src="{{propiedades[6].imagen}}" style="width: 100%; height: 210px;">
            <div class="txtD" *ngIf="propiedades[6].id !== ''">
              <label style="width: 95%; margin-right: 5%; font-weight: bold; font-size: 30px;">Cuadrícula 7</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="dialog.tbl === 'OP'">
    <label style="width: 100%; text-align: center;">{{propiedad.titulo}}</label>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 15%;">
        <span class="input-group-text" id="txtOrden" style="background-color: white; border: none;">Orden</span>
      </div>
      <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtOrden" [(ngModel)]="propiedad.orden">
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button *ngIf="dialog.tbl === 'CP' || dialog.tbl === 'EP'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="crear()">{{dialog.btn}}</button>
  <button *ngIf="dialog.tbl === 'DP'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="guardarC()">{{dialog.btn}}</button>
  <button *ngIf="dialog.tbl === 'OP'" mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="margin: 0 auto;" (click)="guardarO()">{{dialog.btn}}</button>
</div>
