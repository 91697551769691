import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './web/home/home.component';
import { NosotrosComponent } from './web/nosotros/nosotros.component';
import { PropiedadesComponent } from './web/propiedades/propiedades.component';
import { ServiciosComponent } from './web/servicios/servicios.component';
import { ContactoComponent } from './web/contacto/contacto.component';
import { DetalleComponent } from './web/detalle/detalle.component';
import { LoginComponent } from './intranet/login/login.component';
import { DashboardComponent } from './intranet/dashboard/dashboard.component';
import { ConsultaComponent } from './intranet/consulta/consulta.component';
import { UsuarioComponent } from './intranet/usuario/usuario.component';
import { PropiedadComponent } from './intranet/propiedad/propiedad.component';
import { TipoPropComponent } from './intranet/tipo-prop/tipo-prop.component';
import { MonedaComponent } from './intranet/moneda/moneda.component';
import { PaisComponent } from './intranet/pais/pais.component';
import { RegionComponent } from './intranet/region/region.component';
import { ComunaComponent } from './intranet/comuna/comuna.component';
import { ConfigurarComponent } from './intranet/configurar/configurar.component';

const routes: Routes = [
  {path : '', component : HomeComponent},
  {path : 'nosotros', component : NosotrosComponent},
  {path : 'propiedades', component : PropiedadesComponent},
  {path : 'homeProp/:negocio/:tipo/:comuna', component : PropiedadesComponent},
  {path : 'servicios', component : ServiciosComponent},
  {path : 'contacto', component : ContactoComponent},
  {path : 'detalle/:url', component : DetalleComponent},
  {path : 'login', component : LoginComponent},
  {path : 'dashboard', component : DashboardComponent,
    children: [
      {path : 'consulta', component : ConsultaComponent},
      {path : 'usuario', component : UsuarioComponent},
      {path : 'propiedad', component : PropiedadComponent},
      {path : 'tipoProp', component : TipoPropComponent},
      {path : 'moneda', component : MonedaComponent},
      {path : 'pais', component : PaisComponent},
      {path : 'region', component : RegionComponent},
      {path : 'comuna', component : ComunaComponent},
      {path : 'configurar', component : ConfigurarComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
