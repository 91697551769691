import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MailForm } from '../../servicio/mailForm';
import { SendEmailService } from '../../servicio/send-email.service';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  dlg: string;
  idD: string;
  tit: string;
}

export interface eleUsuario {
  apellido_01: string,
  apellido_02: string,
  email: string,
  estado: boolean,
  fecha_crea: number,
  id_perfil: string,
  id_usuario: string,
  nombre_01: string,
  nombre_02: string,
  nuevo: boolean
}

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['nombre', 'perfil', 'email', 'estado', 'accion'];

  constructor(public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private router: Router, private dashboard: DashboardComponent) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(dialogo, id): void {
    let titulo = '';

    switch (dialogo) {
      case 'CU':
        titulo = 'Nuevo Usuario';
        break;
      case 'EU':
        titulo = 'Editar Usuario';
        break;
    }
    this.dialog.open(DialogoUsuario, {
      width: '70%',
      data: {dlg: dialogo, idD: id, tit: titulo}
    });
  }

  ngAfterViewInit(): void {
    this.generalService.scAllDocumentsByOrder2('t_usuario', 'nombre_01', 'apellido_02').subscribe((usuarios: any) => {
      let cant = 1;
      
      usuarios.forEach((usuario: any) => {
        this.generalService.getAllDocumentsConditional('t_perfil', 'id_perfil', '==', usuario.id_perfil).subscribe((perfil: any) => {
          usuario.id_perfil = perfil[0].perfil;

          if (cant === usuarios.length) {
            //this.dataSource = new MatTableDataSource(usuarios);
            this.dataSource = new MatTableDataSource<eleUsuario>(usuarios);
            this.dataSource.paginator = this.paginator;
            localStorage.setItem('load', 'N');
          }
          cant++;
        });
      });
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_usuario', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class DialogoUsuario {
  usuario = {
    apellido_01: '',
    apellido_02: '',
    email: '',
    estado: true,
    fecha_crea: new Date().getTime(),
    id_perfil: '',
    id_usuario: '',
    nombre_01: '',
    nombre_02: '',
    nuevo: true
  };
  perfiles;
  mailForm = new MailForm();

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoUsuario>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo, private sendServices: SendEmailService) {
    this.generalService.getAllDocumentsConditional('t_perfil', 'estado', '==', true).subscribe((perfiles: any) => {
      this.perfiles = perfiles;
    });

    if (dialog.dlg === 'EU') {
      this.generalService.getAllDocumentsConditional('t_usuario', 'id_usuario', '==', dialog.idD).subscribe((datDoc: any) => {
        this.usuario = datDoc[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    if (this.usuario.email !== '' && this.usuario.nombre_01 !== '' && this.usuario.apellido_01 !== '' && this.usuario.id_perfil !== '') {
      this.usuario.email = this.usuario.email.replace(/\s/g, "");

      this.generalService.getAllDocumentsConditional('t_usuario', 'email', '==', this.usuario.email).subscribe((usuario: any) => {
        if (usuario.length === 0) {
          this.generalService.newDocument(this.usuario, 't_usuario').then(successI => {
            this.usuario.id_usuario = successI.id;
            this.authenticationService.SignUp(this.usuario.email, 'NEW2020PASS');

            const update = {
              id_usuario: this.usuario.id_usuario
            };
            this.generalService.updateDocument('t_usuario', successI.id, update).then(successU2 => {
              this.mailForm.tipo = 'Nuevo Usuario';
              this.mailForm.dato1 = this.usuario.nombre_01 + ' ' + this.usuario.apellido_01 + ' ' + this.usuario.apellido_02;
              this.mailForm.dato2 = this.usuario.email;
              this.mailForm.dato3 = this.usuario.id_usuario;
              this.mailForm.dato4 = localStorage.getItem('barra');
              
              this.getSentServices(this.mailForm);
            });
          });
        }
      });
    } else {
      this.openSnackBar('Complete la información solicitada', 'Crear Usuario');
    }
  }

  editar() {
    if (this.usuario.email !== '' && this.usuario.nombre_01 !== '' && this.usuario.apellido_01 !== '' && this.usuario.id_perfil !== '') {
      const update = {
        apellido_01: this.usuario.apellido_01,
        apellido_02: this.usuario.apellido_02,
        id_perfil: this.usuario.id_perfil,
        nombre_01: this.usuario.nombre_01,
        nombre_02: this.usuario.nombre_02
      };
      this.generalService.updateDocument('t_usuario', this.dialog.idD, update).then(successU2 => {
        this.openSnackBar('El usuario se ha editado correctamente.', 'Editar Usuario');
        this.dialogo.close();
      });
    } else {
      this.openSnackBar('Complete la información solicitada', 'Editar Usuario');
    }
  }

  getSentServices(body: MailForm) {
    this.sendServices.getResponseEmail(body).subscribe(
      data => {
        if (data) {
          this.openSnackBar('El usuario se ha creado correctamente.', 'Nuevo Usuario');
          this.dialogo.close();
        } else {
          this.snackBar.open('Ocurrió un error al realizar el envio del mail, intente nuevamente.', 'Error', {
            duration: 2000,
          });
        }
      },
      err => {
        this.snackBar.open('Ups!, Ocurrió un error al realizar el envio del mail, intente nuevamente.', 'Error', {
          duration: 5000,
        });
    });
  }
}
