<div class="container-fluid text-md-center" style="margin-bottom: 120px; background-color: #F3F5F5;">
  <div class="row row-cols-1 row-cols-sm-2" style="width: 80%; margin-left: 10%; padding-top: 20px; margin-bottom: 20px;">
    <div class="col">
      <div class="shadow-sm" style="background-color: white; width: 70%; margin-left: 15%; margin-top: 10px; margin-bottom: 10px; cursor: pointer;" (click)="buscar('A')">
        <div class="card-body">
          <p class="card-text" style="font-size: 25px; line-height: 25px;">ARRIENDO</p>
          <p class="card-text" style="font-size: 12px; line-height: 12px;">{{numArriendos}} PROPIEDADES</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="shadow-sm" style="background-color: white; width: 70%; margin-left: 15%; margin-top: 10px; margin-bottom: 10px; cursor: pointer;" (click)="buscar('V')">
        <div class="card-body">
          <p class="card-text" style="font-size: 25px; line-height: 25px;">VENTA</p>
          <p class="card-text" style="font-size: 12px; line-height: 12px;">{{numVentas}} PROPIEDADES</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="verSpinner" class="spinner-grow" style="width: 3rem; height: 3rem; margin-bottom: 20px;" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div *ngIf="!verSpinner" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-md-4 g-3" style="width: 80%; margin-left: 10%; margin-top: 10px; margin-bottom: 10px;">
    <div *ngFor="let prop of propiedades" class="col">
      <div class="shadow-sm" style="cursor: pointer;" [routerLink]="['/detalle', prop.url]">
        <img src="{{prop.principal}}" style="width: 100%; height: 300px;">
        <div class="card-body">
          <p class="card-text" style="font-size: 15px; line-height: 15px;">{{prop.vendArr}}</p>
          <p class="card-text" style="font-size: 15px; line-height: 15px;">{{prop.comuna}} | {{prop.tipo_prop}}</p>
          <p *ngIf="prop.mt_total > 0" class="card-text" style="font-size: 15px; line-height: 15px;">Sup. {{prop.mt_total | number}} m2</p>
          <p *ngIf="prop.mt_total == 0" class="card-text" style="font-size: 15px; line-height: 15px;">&nbsp;</p>
          <p class="card-text" style="font-size: 20px; line-height: 20px; font-weight: bold;">{{prop.titulo}}</p>
          <p class="card-text">{{prop.moneda}} {{prop.precio | number}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-pie></app-pie>
