<footer class="page-footer font-small blue pt-4 footer" [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra')}" style="width: 100%; padding-top: 20px;">
    <!--<div class="row" style="width: 70%; margin-left: 15%;">
        <div class="col-lg-4"><div style="width: 90%; height: 120px; margin-left: 5%; margin-bottom: 10px; background-color: #42444D;"></div></div>
        <div class="col-lg-4"><div style="width: 90%; height: 120px; margin-left: 5%; margin-bottom: 10px; background-color: #42444D;"></div></div>
        <div class="col-lg-4"><div style="width: 90%; height: 120px; margin-left: 5%; margin-bottom: 10px; background-color: #42444D;"></div></div>
    </div>-->
    <div class="row" style="margin-top: 50px;">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <div class="row" [ngStyle]="{color: authenticationService.localStorageItem('letra_enc')}">
                <div class="col-lg-3">
                    <ul>
                        <li><a href="#" class="nav-link px-2" routerLink="">Inicio</a></li>
                        <li><a href="#" class="nav-link px-2" routerLink="propiedades">Propiedades</a></li>
                        <li><a href="#" class="nav-link px-2" routerLink="servicios">Servicios</a></li>
                        <li><a href="#" class="nav-link px-2" routerLink="nosotros">Nosotros</a></li>
                        <li><a href="#" class="nav-link px-2" routerLink="contacto">Contacto</a></li>
                        <li><div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Busca una propiedad" aria-label="Busca una propiedad" aria-describedby="basic-addon1" style="border-right: 0px;">
                            <span class="input-group-text" id="basic-addon1" style="background-color: white; border-top-right-radius: 40%; border-bottom-right-radius: 40%;"><mat-icon>search</mat-icon></span>
                        </div></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <p style="font-size: 20px; line-height: 2; font-weight: bold;">Contactar</p>
                    <p style="font-size: 16px; line-height: 2; font-weight: normal;">{{authenticationService.localStorageItem('direccion')}}</p>
                    <p style="font-size: 16px; line-height: 2; font-weight: normal;">{{authenticationService.localStorageItem('telefono')}}</p>
                    <p style="font-size: 16px; line-height: 2; font-weight: normal;">{{authenticationService.localStorageItem('whatsapp')}}</p>
                    <p style="font-size: 16px; line-height: 2; font-weight: normal;">secretaria@colladokunstmann.cl</p>
                </div>
                <div class="col-lg-3" style="font-weight: bold; font-size: 20px;">
                    <p style="font-size: 20px; line-height: 2; font-weight: bold;">Síguenos</p>
                    <p>
                        <a href="https://www.facebook.com/Collado-Kunstmann-Propiedades-317862240536" target="_blank"><img src="../../../assets/img/facebook.png" alt="Facebook" style="width: 25px; height: 25px; margin-right: 10px;" /></a>
                        <a href="https://www.instagram.com/collado_kunstmann/?hl=es-la" target="_blank"><img src="../../../assets/img/instagram.png" alt="Instagram" style="width: 25px; height: 25px; margin-right: 10px;" /></a>
                        <a href="https://api.whatsapp.com/send?phone={{whatsapp}}" target="_blank"><img src="../../../assets/img/whatsapp.png" alt="Whatsapp" style="width: 25px; height: 25px; margin-right: 10px;" /></a>
                        <a href="mailto:secretaria@colladokunstmann.cl?subject=Contacto Web" target="_blank"><img src="../../../assets/img/mail.png" alt="Mail" style="width: 25px; height: 25px;" /></a>
                    </p>
                </div>
                <div class="col-lg-3"><img class="site-logo" src="{{authenticationService.localStorageItem('icono')}}" alt="Collado + Kunstmann" style="width: 100%; height: auto;" /></div>
            </div>
        </div>
        <div class="col-lg-2"></div> 
    </div>
    <div class="footer-copyright text-center py-3" style="color: white; font-size: 10px;">
        <br>Todos los derechos reservados - {{agno}} C+K | Desarrollado por <a href="https://rademacher.cl">RA Tech</a> | <label style="font-size: 6px; font-weight: bold;">versión 2.0.3.0</label>
    </div>
</footer>