export class MailForm {
    public tipo: string;
    public dato1: string;
    public dato2: string;
    public dato3: string;
    public dato4: string;
    public dato5: string;
    public dato6: string;
    public dato7: string;
}
