import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable()
export class GeneralService {

  constructor(
    public angularFirestore: AngularFirestore,
  ) { }

  /* GET */
  getDocument(resource, id) {
    return this.angularFirestore.collection(resource).doc(id).get().pipe(
      map(a => {
        const data = a.data();
        return { ...(data as {}) };
      })
    );
  }

  getAllDocuments(resource) {
    return this.angularFirestore.collection(resource).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  /* SNAPSHOT */
  scDocument(resource, id) {
    return this.angularFirestore.collection(resource).doc(id).snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data();
        const idA = action.payload.id;
        return { idA, ...(data as {}) };
      }));
  }

  scAllDocumentsConditional2ByOrder(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional3ByOrder(resource, a, b, c, d, e, f, g, h, i, j) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).orderBy(j)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional4ByOrder(resource, a, b, c, d, e, f, g, h, i, j, k, l, m) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).where(j, k, l).orderBy(m)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional2ByOrderDesc(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  /* VARIOS */
  newId() {
    return this.angularFirestore.createId();
  }

  getAllDocumentsByOrder(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsByOrderDesc(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a, 'desc')).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional(resource, a, b, c) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional2(resource, a, b, c, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(e, f, g)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional3(resource, a, b, c, e, f, g, h, i, j) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(e, f, g).where(h, i, j)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional3Limit(resource, a, b, c, e, f, g, h, i, j, k) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(e, f, g).where(h, i, j).limit(k)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional4(resource, a, b, c, e, f, g, h, i, j, k, l, m) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(e, f, g).where(h, i, j).where(k, l, m)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional5(resource, a, b, c, e, f, g, h, i, j, k, l, m, n, o, p) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(e, f, g).where(h, i, j).where(k, l, m).where(n, o, p)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrderLimit(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).limit(e)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalLimit(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).limit(d)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrderDescLimit(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d, 'desc').limit(e)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrderDesc(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrder(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional2ByOrder(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional4ByOrder(resource, a, b, c, d, e, f, g, h, i, j, k, l, m) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).where(j, k, l).orderBy(m)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional2ByOrderDesc(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g, 'desc')).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }
  /* *********************** */

  /* snapshotChanges */
  scAllDocuments(resource) {
    return this.angularFirestore.collection(resource, ref => ref).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional(resource, a, b, c) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional2(resource, a, b, c, d, e, f) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional3(resource, a, b, c, d, e, f, g, h, i) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional4(resource, a, b, c, d, e, f, g, h, i, j, k, l) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).where(j, k, l)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsByOrder(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsByOrder2(resource, a, b) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a).orderBy(b)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsByOrderDesc(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditionalByOrderDesc(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }
  /* ******************** */

  editDocument(resource, id, object) {
    return this.angularFirestore.collection(resource).doc(id).set(
      object
    );
  }

  updateDocument(resource, id, object) {
    return this.angularFirestore.collection(resource).doc(id).update(
      object
    );
  }

  newDocument(object, name) {
    return this.angularFirestore.collection(name).add(
      object
    );
  }

  newDocumentWithId(object, name, id) {
    return this.angularFirestore.collection(name).doc(id).set(
      object
    );
  }

  deleteDocument(name, id) {
    return this.angularFirestore.collection(name).doc(id).delete();
  }
}