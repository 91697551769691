import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { GeneralService } from './general.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/map';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userData: Observable<firebase.default.User>;

  constructor(private generalService: GeneralService,
              private angularFireAuth: AngularFireAuth,
              private router: Router,
              private _snackBar: MatSnackBar) {
    this.userData = angularFireAuth.authState;
  }

  openSnackBar(message: string, action: string) {
    localStorage.setItem('load', 'N');
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  SignUp(email: string, password: string) {
    this.angularFireAuth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Successfully signed up!', res);
      })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  SignIn(email: string, password: string) {
    localStorage.setItem('web', '');
    localStorage.setItem('id_usuario', '');
    localStorage.setItem('perfil', '');
    localStorage.setItem('nombre', '');
    localStorage.setItem('login', 'false');
    localStorage.setItem('load', 'N');
    localStorage.setItem('numChat', '');

    if (email !== '' && password !== '') {
      this.generalService.getAllDocumentsConditional('t_usuario', 'email', '==', email).subscribe((usuario: any) => {
        if (usuario.length > 0) {
          if (usuario[0].estado === true) {
            this.angularFireAuth.signInWithEmailAndPassword(email, password).then(
              res => {
                this.generalService.getAllDocumentsConditional('t_perfil', 'id_perfil', '==', usuario[0].id_perfil).subscribe((perfil: any) => {
                  localStorage.setItem('web', 'CK');
                  localStorage.setItem('login', 'true');
                  localStorage.setItem('load', 'N');
                  localStorage.setItem('id_usuario', CryptoJS.AES.encrypt(usuario[0].id_usuario, 'CK2020').toString());
                  localStorage.setItem('perfil', perfil[0].perfil);
                  localStorage.setItem('nombre', usuario[0].nombre_01 + ' ' + usuario[0].apellido_01 + ' ' + usuario[0].apellido_02);
    
                  this.router.navigateByUrl('dashboard');
                });
              }
            )
            .catch(Error => {
              localStorage.setItem('login', 'false');
  
              this.openSnackBar('Correo electrónico o contraseña incorrecta, intente nuevamente...', 'Error LogIn');
            });
          } else {
            this.openSnackBar('El usuario no se encuentra habilitado, contáctese con el administrador...', 'Error LogIn');
          }
        } else {
          this.openSnackBar('Correo electrónico incorrecto, intente nuevamente...', 'Error LogIn');
        }
      });
    } else {
      if (email === '' && password === '') {
        this.openSnackBar('Debe ingresar el correo electrónico y la contraseña, intente nuevamente...', 'Error LogIn');
      } else {
        if (email === '') {
          this.openSnackBar('Debe ingresar el correo electrónico, intente nuevamente...', 'Error LogIn');
        } else {
          this.openSnackBar('Debe ingresar la contraseña, intente nuevamente...', 'Error LogIn');
        }
      }
    }
  }

  SignOut() {
    localStorage.setItem('web', '');
    localStorage.setItem('id_usuario', '');
    localStorage.setItem('perfil', '');
    localStorage.setItem('nombre', '');
    localStorage.setItem('login', 'false');
    localStorage.setItem('load', 'N');

    this.angularFireAuth.signOut();
    this.router.navigateByUrl('');
  }

  LogIn(param) {
    this.loggedIn.next(true);
    this.router.navigate([param]);
  }

  public localStorageItem(id: string): string {
    if (id === 'logo' || id === 'icono' || id === 'id_usuario') {
      if (localStorage.getItem(id) !== undefined && localStorage.getItem(id) !== null) {
        return CryptoJS.AES.decrypt(localStorage.getItem(id), 'CK2020').toString(CryptoJS.enc.Utf8);
      } else {
        return '';
      }
    } else {
      return localStorage.getItem(id);
    }
  }
}
