<div class="container">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)">
        <mat-icon title="Volver" style="cursor: pointer; font-size: 35px;" (click)="volver()">reply</mat-icon>
        <h2 style="margin-left: 45%; margin-top: -35px;">CONFIGURAR</h2>
    </div>
    <nav class="nav nav-pills flex-column flex-sm-row" [ngStyle]="{borderBottom: color[0].borde}" style="margin-top: 20px;">
        <div class="flex-sm-fill text-sm-center nav-link" (click)="ver(1)" [ngStyle]="{backgroundColor: color[0].fondo, color: color[0].letra}" style="cursor: pointer; font-weight: bold;">General</div>
        <div class="flex-sm-fill text-sm-center nav-link" (click)="ver(2)" [ngStyle]="{backgroundColor: color[1].fondo, color: color[1].letra}" style="cursor: pointer; font-weight: bold;">Nosotros</div>
        <div class="flex-sm-fill text-sm-center nav-link" (click)="ver(3)" [ngStyle]="{backgroundColor: color[2].fondo, color: color[2].letra}" style="cursor: pointer; font-weight: bold;">Servicios</div>
    </nav>
    <div *ngIf="verGeneral" class="container" style="margin-top: 20px;">
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtDireccion" style="background-color: white; border: none;">Dirección</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtDireccion" [(ngModel)]="configurar.direccion">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtTelefono" style="background-color: white; border: none;">Teléfono</span>
            </div>
            <input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtTelefono" [(ngModel)]="configurar.telefono">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtTelefono" style="background-color: white; border: none;">Whatsapp</span>
            </div>
            +56&nbsp;<input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtWhatsapp1" [(ngModel)]="whatsapp_01">
            &nbsp;&nbsp;+56&nbsp;<input type="number" class="form-control" aria-label="Sizing example input" aria-describedby="txtWhatsapp2" [(ngModel)]="whatsapp_02">
        </div>
        <button mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="width: 30%; margin-left: 35%;" (click)="actualizar(1)">Actualizar</button>
    </div>
    <div *ngIf="verNosotros" class="container" style="margin-bottom: 20px;">
        <div class="form-group shadow-textarea">
            <label for="textareaNosotros">Texto Nosotros</label>
            <textarea class="form-control z-depth-1" id="textareaNosotros" rows="5" [(ngModel)]="nosotros.dato_01"></textarea>
        </div>
        <div class="form-group shadow-textarea">
            <label for="textareaMision">Texto Misión</label>
            <textarea class="form-control z-depth-1" id="textareaMision" rows="5" [(ngModel)]="nosotros.dato_02"></textarea>
        </div>
        <div class="form-group shadow-textarea">
            <label for="textareaVision">Texto Visión</label>
            <textarea class="form-control z-depth-1" id="textareaVision" rows="5" [(ngModel)]="nosotros.dato_03"></textarea>
        </div>
        <button mat-button [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="width: 30%; margin-left: 35%;" (click)="actualizar(2)">Actualizar</button>
    </div>
    <div *ngIf="verServicios" class="container">

    </div>
</div>