import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  tbl: string;
  idT: string;
  tit: string;
  btn: string;
}

export interface eleTipoP {
  estado: string;
  id_tipo_prop: string;
  tipo_prop: string;
}

@Component({
  selector: 'app-tipo-prop',
  templateUrl: './tipo-prop.component.html',
  styleUrls: ['./tipo-prop.component.scss']
})
export class TipoPropComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['tipo_prop', 'estado', 'accion'];

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private dashboard: DashboardComponent) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(tabla, id) {
    let titulo = '';
    let boton = '';

    switch (tabla) {
      case 'CT':
        titulo = 'Nuevo Tipo propiedad';
        boton = 'Crear Tipo propiedad';
        break;
      case 'ET':
        titulo = 'Editar Tipo propiedad';
        boton = 'Editar Tipo propiedad';
        break;
    }
    this.dialog.open(DialogoTipoProp, {
      width: '70%',
      data: {tbl: tabla, idT: id, tit: titulo, btn: boton}
    })
  }

  ngAfterViewInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsByOrder('t_tipo_prop', 'tipo_prop').subscribe((tiposP: any) => {
      this.dataSource = new MatTableDataSource<eleTipoP>(tiposP);
      this.dataSource.paginator = this.paginator;
      localStorage.setItem('load', 'N');
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const objTP = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_tipo_prop', id, objTP).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoTipoProp {
  tipoP = {
    estado: true,
    fecha_crea: new Date().getTime(),
    id_tipo_prop: '',
    tipo_prop: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoTipoProp>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (dialog.tbl === 'ET') {
      this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', dialog.idT).subscribe((tipoProp: any) => {
        this.tipoP = tipoProp[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    switch (this.dialog.tbl) {
      case 'CT':
        if (this.tipoP.tipo_prop !== '') {
          this.generalService.newDocument(this.tipoP, 't_tipo_prop').then(successI => {
            const update = {
              id_tipo_prop: successI.id
            };
            this.generalService.updateDocument('t_tipo_prop', successI.id, update).then(successU2 => {
              this.openSnackBar('El tipo propiedad se ha creado correctamente.', 'Nuevo Tipo propiedad');
              this.dialogo.close();
            });
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Nuevo Tipo propiedad');
        }
        break;
      case 'ET':
        if (this.tipoP.tipo_prop !== '') {
          this.generalService.updateDocument('t_tipo_prop', this.tipoP.id_tipo_prop, this.tipoP).then(successU2 => {
            this.openSnackBar('El tipo propiedad se ha editado correctamente.', 'Editar Tipo propiedad');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Editar Tipo propiedad');
        }
        break;
    }
  }
}
