<div class="container" style="margin-bottom: 100px;">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)"><mat-icon (click)="volver()" title="Volver" style="cursor: pointer; font-size: 35px;">reply</mat-icon><h2 style="margin-left: 45%; margin-top: -35px;">REGIONES</h2></div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
      <ng-container matColumnDef="pais">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> País </th>
        <td mat-cell *matCellDef="let element"> {{element.id_pais}} </td>
      </ng-container>
      <ng-container matColumnDef="region">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Región </th>
        <td mat-cell *matCellDef="let element"> {{element.id_region}} </td>
      </ng-container>
      <ng-container matColumnDef="comuna">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Comuna </th>
        <td mat-cell *matCellDef="let element"> {{element.comuna}} </td>
      </ng-container>
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Estado</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle checked="{{element.estado}}" color="#D4691F" (change)="estado(element.id_comuna, $event)"></mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Acciones </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.estado" (click)="dialogo('EC', element.id_comuna)" style="cursor: pointer;" title="Editar">create</mat-icon>
          <mat-icon *ngIf="!element.estado" style="cursor: default; color: lightgrey;" title="Editar">create</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    <div style="z-index:5; position : fixed; float: right; bottom: 5%; right: 2%; margin-bottom : 0px;">
      <a mat-fab [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="font-weight: bold;" (click)="dialogo('CC', '')"><i class="material-icons">add</i></a>
    </div>
</div>
