<div class="container-fluid text-md-center" style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
    <div class="row" style="width: 70%; margin-left: 15%; margin-top: 40px; margin-bottom: 40px;">
        <div class="col">
            <p style="font-size: 4.2vw;">Trayectoria histórica</p>
            <p>Tenemos más de 20 años de experiencia en el corretaje de propiedades, tanto urbanas como agrícolas, además, realizamos tasaciones a particulares y a empresas. Un equipo a su servicio. En cada una de las áreas usted como cliente tendrá siempre un profesional a su disposición, dispuesto a resolver cualquier situación o consulta que se pueda presentar.</p>
        </div>
        <div class="col">
            <img src="../../../assets/img/valdivia2.jpeg" style="width: 100%; height: 50vh;" class="d-block" alt="...">
        </div>
    </div>
</div>
<div class="container-fluid text-md-center" style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: white; padding-bottom: 40px;" [ngStyle]="{background: authenticationService.localStorageItem('letra')}">
    <div class="row" style="width: 70%; margin-left: 15%; padding-top: 40px; margin-bottom: 40px;">
        <div class="col"><div style="background: white; width: 15vw; height: 20vh;"><div style="width: 90%; height: 95%; margin-left: 5%; font-weight: bold; font-size: 1.8vw;" [ngStyle]="{background: authenticationService.localStorageItem('letra')}">Misión</div></div></div>
        <div class="col" style="text-align: left;">
            <p>Ofrecer a nuestros clientes un servicio integral a la hora de tomar una buena decisión inmobiliaria, diferenciarnos por un servicio profesional y personalizado en todas las etapas de la operación, esperando siempre satisfacer de la mejor manera sus necesidades de venta, compra o arriendo.</p>
        </div>
    </div>
</div>
<div class="container-fluid text-md-center" style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;" [ngStyle]="{color: authenticationService.localStorageItem('letra')}">
    <div class="row" style="width: 70%; margin-left: 15%; padding-top: 40px; margin-bottom: 40px;">
        <div class="col" style="text-align: left;">
            <p>Ser una empresa lider en bienes raíces, reconocida por siempre entregar los mejores resultados a nuestros clientes, destacar por un buen servicio, seriedad y profesionalismo, que nuestro trabajo siempre hable por nosotros, impulsando nuestro crecimiento como empresa.</p>
        </div>
        <div class="col"><div style="width: 15vw; height: 20vh;" [ngStyle]="{background: authenticationService.localStorageItem('letra')}"><div style="background: white; width: 90%; height: 95%; margin-left: 5%; font-weight: bold; font-size: 1.8vw;">Visión</div></div></div>
    </div>
</div>
<app-pie></app-pie>
