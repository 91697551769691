import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, EmailValidator } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MailForm } from '../../servicio/mailForm';
import { SendEmailService } from '../../servicio/send-email.service';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralService } from '../../servicio/general.service';
import { AuthenticationService } from '../../servicio/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

export interface Dialogo {
  tbl: string;
  idP: string;
  cdg: string;
  tipo: string;
  precio: number;
  mon: string;
}

@Component({
  selector: 'app-propiedades',
  templateUrl: './propiedades.component.html',
  styleUrls: ['./propiedades.component.scss']
})
export class PropiedadesComponent implements OnInit {
  verProp = true;
  verDet = false;
  tipoProp;
  comuna;
  propiedades;
  propiedad;
  numArriendos = 0;
  numVentas = 0;
  imgPrin = [];
  imagenes = [];
  buscarD = {
    negocio: '',
    tipo: '',
    comuna: ''
  };
  coordenadas = false;
  imgSlide = '';
  showNavigationArrows = true;
  showNavigationIndicators = false;
  verSpinner = false;

  constructor(public dialog: MatDialog, private generalService: GeneralService, public authenticationService: AuthenticationService,
              private rutaActiva: ActivatedRoute, private router: Router) {
    this.generalService.getAllDocumentsConditionalByOrder('t_tipo_prop', 'estado', '==', true, 'tipo_prop').subscribe(tipoProp => {
      this.generalService.getAllDocumentsConditionalByOrder('t_comuna', 'estado', '==', true, 'comuna').subscribe(comuna => {
        this.generalService.getAllDocumentsConditional4ByOrder('t_propiedad', 'estado', '==', true, 'publicar', '==', true, 'vend_arr', '==', false, 'negocio', '==', 'A', 'orden').subscribe((arriendos: any) => {
          this.generalService.getAllDocumentsConditional4ByOrder('t_propiedad', 'estado', '==', true, 'publicar', '==', true, 'vend_arr', '==', false, 'negocio', '==', 'V', 'orden').subscribe((ventas: any) => {
            this.tipoProp = tipoProp;
            this.comuna = [];
            this.comuna = comuna;
            this.numArriendos = arriendos.length;
            this.numVentas = ventas.length;
            this.buscarD.negocio = '';

            /*if (this.rutaActiva.snapshot.params.negocio !== undefined) {
              if (this.rutaActiva.snapshot.params.negocio === 'Arriendo') {
                this.buscarD.negocio = 'A';
              }
        
              if (this.rutaActiva.snapshot.params.negocio === 'Venta') {
                this.buscarD.negocio = 'V';
              }
              //this.buscarD.comuna = this.rutaActiva.snapshot.params.comuna;
              //this.buscarD.tipo = this.rutaActiva.snapshot.params.tipo;
            }*/
            this.buscar('');
          });
        });
      });
    });
  }

  dialogo(tabla, idProp, codigo, tipoP, valor, moneda): void {
    this.dialog.open(DialogoPropiedades, {
      width: '80%',
      data: {tbl: tabla, idP: idProp, cdg: codigo, tipo: tipoP, precio: valor, mon: moneda}
    });
  }

  ngOnInit(): void { }

  buscar(negocio: string) {
    this.verSpinner = true;
    this.propiedades = [];

    if (negocio === '') {
      this.generalService.scAllDocumentsConditional3ByOrder('t_propiedad', 'estado', '==', true, 'publicar', '==', true, 'vend_arr', '==', false, 'orden').subscribe((propiedades: any) => {
        if (propiedades.length > 0) {
          let cant = 1;
          
          propiedades.forEach((propiedad: any) => {
            this.generalService.getAllDocumentsConditional3('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'estado', '==', true, 'principal', '==', true).subscribe((principal: any) => {
              this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', propiedad.id_moneda).subscribe((moneda: any) => {
                this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', propiedad.id_tipo_prop).subscribe((tipoProp: any) => {
                  this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', propiedad.id_comuna).subscribe((comuna: any) => {
                    if (propiedad.negocio === 'A') {
                      propiedad.vendArr = 'Arriendo';
                    } else {
                      propiedad.vendArr = 'Venta';
                    }
                    propiedad.comuna = comuna[0].comuna;
                    propiedad.tipo_prop = tipoProp[0].tipo_prop;
                    propiedad.moneda = moneda[0].moneda;
  
                    let txtTit = propiedad.titulo.toLowerCase();
  
                    propiedad.tituloURL = txtTit.replace(/ /g, "-");
                    propiedad.principal = '../../../assets/img/sinFoto.png';
  
                    if (principal.length > 0) {
                      propiedad.principal = principal[0].url;
                    }
                    propiedad.foto1 = '';
                    propiedad.foto2 = '';
                    propiedad.foto3 = '';
            
                    this.generalService.getAllDocumentsConditional3Limit('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'principal', '==', false, 'estado', '==', true, 3).subscribe((fotos: any) => {
                      switch (fotos.length) {
                        case 1:
                          propiedad.foto1 = fotos[0].url;
                          break;
                        case 2:
                          propiedad.foto1 = fotos[0].url;
                          propiedad.foto2 = fotos[1].url;
                          break;
                        case 3:
                          propiedad.foto1 = fotos[0].url;
                          propiedad.foto2 = fotos[1].url;
                          propiedad.foto3 = fotos[2].url;
                          break;
                      }
                    });
  
                    if (cant === propiedades.length) {
                      this.propiedades = propiedades;
                      this.verSpinner = false;
                    }
                    cant++;
                  });
                });
              });
            });
          });
        } else {
          this.verSpinner = false;
        }
      });
    } else {
      this.generalService.scAllDocumentsConditional4ByOrder('t_propiedad', 'estado', '==', true, 'publicar', '==', true, 'vend_arr', '==', false, 'negocio', '==', negocio, 'orden').subscribe((propiedades: any) => {
        if (propiedades.length > 0) {
          let cant = 1;
          
          propiedades.forEach((propiedad: any) => {
            this.generalService.getAllDocumentsConditional3('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'estado', '==', true, 'principal', '==', true).subscribe((principal: any) => {
              this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', propiedad.id_moneda).subscribe((moneda: any) => {
                this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', propiedad.id_tipo_prop).subscribe((tipoProp: any) => {
                  this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', propiedad.id_comuna).subscribe((comuna: any) => {
                    if (propiedad.negocio === 'A') {
                      propiedad.vendArr = 'Arriendo';
                    } else {
                      propiedad.vendArr = 'Venta';
                    }
                    propiedad.comuna = comuna[0].comuna;
                    propiedad.tipo_prop = tipoProp[0].tipo_prop;
                    propiedad.moneda = moneda[0].moneda;
  
                    let txtTit = propiedad.titulo.toLowerCase();
  
                    propiedad.tituloURL = txtTit.replace(/ /g, "-");
                    propiedad.principal = '../../../assets/img/sinFoto.png';
  
                    if (principal.length > 0) {
                      propiedad.principal = principal[0].url;
                    }
                    propiedad.foto1 = '';
                    propiedad.foto2 = '';
                    propiedad.foto3 = '';
            
                    this.generalService.getAllDocumentsConditional3Limit('t_propiedad_img', 'id_propiedad', '==', propiedad.id_propiedad, 'principal', '==', false, 'estado', '==', true, 3).subscribe((fotos: any) => {
                      switch (fotos.length) {
                        case 1:
                          propiedad.foto1 = fotos[0].url;
                          break;
                        case 2:
                          propiedad.foto1 = fotos[0].url;
                          propiedad.foto2 = fotos[1].url;
                          break;
                        case 3:
                          propiedad.foto1 = fotos[0].url;
                          propiedad.foto2 = fotos[1].url;
                          propiedad.foto3 = fotos[2].url;
                          break;
                      }
                    });
  
                    if (cant === propiedades.length) {
                      this.propiedades = propiedades;
                      this.verSpinner = false;
                    }
                    cant++;
                  });
                });
              });
            });
          });
        } else {
          this.verSpinner = false;
        }
      });
    }
  }

  detalle(idPropiedad, x, y) {
    //console.log('Detalle: ' + x + ' * ' + y);
    localStorage.setItem('coorX', x);
    localStorage.setItem('coorY', y);
    const valW = +localStorage.getItem('width');
    const valWR = Math.round((valW * 20) / 100) + 130;
    const calc = Math.round((valW - valWR) / 4);
    this.imgSlide = calc + 'px';
    this.propiedad = [];

    this.generalService.getAllDocumentsConditional('t_propiedad', 'id_propiedad', '==', idPropiedad).subscribe((infoProp: any) => {
      this.coordenadas = false;
      //localStorage.setItem('coorX', '0');
      //localStorage.setItem('coorY', '0');

      if (infoProp[0].coorX !== 0 && infoProp[0].coorY !== 0) {
        //localStorage.setItem('coorX', infoProp[0].coorX);
        //localStorage.setItem('coorY', infoProp[0].coorY);
        this.coordenadas = true;
      }
      
      this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', infoProp[0].id_comuna).subscribe((comuna: any) => {
        infoProp[0].id_comuna = comuna[0].comuna;
      });

      // tslint:disable-next-line: max-line-length
      this.generalService.getAllDocumentsConditional('t_tipo_prop', 'id_tipo_prop', '==', infoProp[0].id_tipo_prop).subscribe((tipoProp: any) => {
        infoProp[0].id_tipo_prop = tipoProp[0].tipo_prop;
      });

      this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', infoProp[0].id_moneda).subscribe((moneda: any) => {
        infoProp[0].id_moneda = moneda[0].moneda;
      });

      if (infoProp[0].negocio === 'A') {
        infoProp[0].negocio = 'Arriendo';
      } else {
        infoProp[0].negocio = 'Venta';
      }

      this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', infoProp[0].id_propiedad, 'principal', '==', true).subscribe((principal: any) => {
        if (principal.length > 0) {
          infoProp[0].principal = principal[0].url;
        } else {
          infoProp[0].principal = '../../../assets/img/sinFoto.png';
        }
      });
      this.propiedad = infoProp[0];

      this.generalService.getAllDocumentsConditional2('t_propiedad_img', 'id_propiedad', '==', idPropiedad, 'estado', '==', true).subscribe((imagenes: any) => {
        this.imagenes = [];
        this.imgPrin = [{url: ''}, {url: ''}, {url: ''}, {url: ''}];

        if (imagenes.length > 4) {
          let cantS = 0;
          let cantST = 0;
          let cantT = 1;
  
          imagenes.forEach((imagen: any) => {
            if (cantT < 5) {
              this.imgPrin[cantS].url = imagen.url;
            } else {
              if (cantS === 0) {
                this.imagenes.push([{url: ''}, {url: ''}, {url: ''}, {url: ''}]);
              }
              this.imagenes[cantST][cantS].url = imagen.url;
  
              if (cantS === 3) {
                cantST++;
              }
            }
            cantS++;
            cantT++;
  
            if (cantS === 4) {
              cantS = 0;
            }
          });
        } else {
          if (imagenes.length > 0) {
            let cantI = 0;
    
            imagenes.forEach((imagen: any) => {
              this.imgPrin[cantI].url = imagen.url;
              cantI++;
            });
          }
        }
      });
      this.verProp = false;
      this.verDet = true;

      $('#carouselExampleSlidesOnly').carousel({
        interval: 3000,
        cycle: true
      });
    });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: './dialogo.component.html',
  styleUrls: ['./propiedades.component.scss']
})
export class DialogoPropiedades implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new MailForm();
  verBoton = true;

  constructor(private sendServices: SendEmailService, public snackBar: MatSnackBar,
              public dialogo: MatDialogRef<Dialogo>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo,
              private generalService: GeneralService) { }

  ngOnInit() {
  }

  cerrar() {
    this.dialogo.close();
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo invalido' :
        '';
  }

  onSubmit(f: NgForm, tipoF) {
    this.verBoton = false;
    this.ContactModel.tipo = tipoF;
    this.ContactModel.dato1 = 'Contacto';
    this.ContactModel.dato6 = this.dialog.cdg + ' - ' + this.dialog.tipo + ' - ' + this.dialog.mon + ' ' + this.dialog.precio;
    this.getSentServices(this.ContactModel, f);
  }

  getSentServices(body: MailForm, f: NgForm) {
    this.sendServices.getResponseEmail(body).subscribe(
      data => {
        if (data) {
          const objN = {
            asunto: this.dialog.cdg + ' - ' + this.dialog.tipo + ' - ' + this.dialog.mon + ' ' + this.dialog.precio,
            email: this.ContactModel.dato4,
            estado: 'N',
            fecha_crea: new Date().getTime(),
            id_consulta: '',
            mensaje: this.ContactModel.dato7,
            nombre: this.ContactModel.dato3,
            telefono: this.ContactModel.dato5,
            tipo: 'P'
          }
          this.generalService.newDocument(objN, 't_consulta').then(successI => {
            const update = {
              id_consulta: successI.id
            };
            this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
              this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
                duration: 5000,
              });
              this.dialogo.close();
            });
          });
        } else {
          this.snackBar.open(':(', 'Error', {
            duration: 2000,
          });
        }
      },
      err => { /*this.snackBar.open('Algo fallo :/', 'ups', {
        duration: 5000,
      });*/
      const objN = {
        asunto: this.dialog.cdg + ' - ' + this.dialog.tipo + ' - ' + this.dialog.mon + ' ' + this.dialog.precio,
        email: this.ContactModel.dato4,
        estado: 'N',
        fecha_crea: new Date().getTime(),
        id_consulta: '',
        mensaje: this.ContactModel.dato7,
        nombre: this.ContactModel.dato3,
        telefono: this.ContactModel.dato5,
        tipo: 'P'
      }
      this.generalService.newDocument(objN, 't_consulta').then(successI => {
        const update = {
          id_consulta: successI.id
        };
        this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
          this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
            duration: 5000,
          });
          this.dialogo.close();
        });
      });
    });
  }
}
