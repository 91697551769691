<div *ngIf="verDashboard" class="container-fluid" style="margin-top: 20px; margin-bottom: 20px;">
    <div *ngIf="authenticationService.localStorageItem('perfil') === 'Administrador'" class="row">
        <div class="col-sm">
            <h3 style="text-align: center; margin-bottom: 20px;">Menú administrador</h3>
            <div class="row" [ngStyle]="{'color': authenticationService.localStorageItem('letra')}" style="margin: 0 auto;">
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/consulta.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="consulta" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Consultas</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/usuario.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="usuario" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Usuarios</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/propiedad.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="propiedad" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Propiedades</div>
                </div>
            </div>
            <div class="row" [ngStyle]="{'color': authenticationService.localStorageItem('letra')}" style="margin: 0 auto; margin-top: 5%;">
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/configuracion.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="configurar" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Configuración</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/tipo_prop.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="tipoProp" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Tipo propiedad</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/moneda.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="moneda" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Moneda</div>
                </div>
            </div>
            <div class="row" [ngStyle]="{'color': authenticationService.localStorageItem('letra')}" style="margin: 0 auto; margin-top: 5%;">
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/pais.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="pais" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">País</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/region.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="region" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Región</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/comuna.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%;" routerLink="comuna" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Comuna</div>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
