import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  tbl: string;
  idP: string;
  tit: string;
  btn: string;
}

export interface elePais {
  estado: string;
  id_pais: string;
  pais: string;
}

@Component({
  selector: 'app-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.scss']
})
export class PaisComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['pais', 'estado', 'accion'];

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private dashboard: DashboardComponent) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(tabla, id) {
    let titulo = '';
    let boton = '';

    switch (tabla) {
      case 'CP':
        titulo = 'Nuevo País';
        boton = 'Crear País';
        break;
      case 'EP':
        titulo = 'Editar País';
        boton = 'Editar País';
        break;
    }
    this.dialog.open(DialogoPais, {
      width: '70%',
      data: {tbl: tabla, idP: id, tit: titulo, btn: boton}
    })
  }

  ngAfterViewInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsByOrder('t_pais', 'pais').subscribe((paises: any) => {
      //this.dataSource = new MatTableDataSource(paises);
      this.dataSource = new MatTableDataSource<elePais>(paises);
      this.dataSource.paginator = this.paginator;
      localStorage.setItem('load', 'N');
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const objC = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_pais', id, objC).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoPais {
  pais = {
    estado: true,
    fecha_crea: new Date().getTime(),
    id_pais: '',
    pais: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoPais>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (dialog.tbl === 'EP') {
      this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', dialog.idP).subscribe((pais: any) => {
        this.pais = pais[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    switch (this.dialog.tbl) {
      case 'CP':
        if (this.pais.pais !== '') {
          this.generalService.newDocument(this.pais, 't_pais').then(successI => {
            const update = {
              id_pais: successI.id
            };
            this.generalService.updateDocument('t_pais', successI.id, update).then(successU2 => {
              this.openSnackBar('El pais se ha creado correctamente.', 'Nuevo Pais');
              this.dialogo.close();
            });
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Nuevo Pais');
        }
        break;
      case 'EP':
        if (this.pais.pais !== '') {
          this.generalService.updateDocument('t_pais', this.pais.id_pais, this.pais).then(successU2 => {
            this.openSnackBar('El pais se ha editado correctamente.', 'Editar Pais');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Editar Pais');
        }
        break;
    }
  }
}
