<div style="margin-bottom: 20px; width: 90%; margin-left: 5%;">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)"><mat-icon (click)="volver()" title="Volver" style="cursor: pointer; font-size: 35px;">reply</mat-icon><h2 style="margin-left: 45%; margin-top: -35px;">PROPIEDADES</h2></div>
    <div style="margin-bottom: 2%; text-align: center; width: 100%;">
        <mat-form-field style="width: 40%;">
            <input matInput (keyup)="filtrar($event)" placeholder="Buscar">
        </mat-form-field>
    </div>
    <div style="margin-bottom: 2%; text-align: center; width: 30%; float: right;">
        <select class="custom-select" id="cbNegocio" placeholder="Estado" [(ngModel)]="estadoP" (change)="buscar()">
            <option value="H" selected>Habilitado</option>
            <option value="D">Deshabilitado</option>
          </select>
    </div>
    <div style="font-size: 12px; width: 100%;">Nota: Si es primera vez que comparte la propiedad, debe ingresar dos veces en "Compartir" para que la información aparesca correctamente.</div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Cód. </th>
            <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
        </ng-container>
        <ng-container matColumnDef="tipo_prop">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Tipo prop. </th>
            <td mat-cell *matCellDef="let element"> {{element.tipo_prop}} </td>
        </ng-container>
        <ng-container matColumnDef="propietario">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Propietario </th>
            <td mat-cell *matCellDef="let element"> {{element.propietario}} </td>
        </ng-container>
        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Dirección </th>
            <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
        </ng-container>
        <ng-container matColumnDef="titulo">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Título </th>
            <td mat-cell *matCellDef="let element"> {{element.titulo}} </td>
        </ng-container>
        <ng-container matColumnDef="precio">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Precio </th>
            <td mat-cell *matCellDef="let element"> {{element.precio | number}} </td>
        </ng-container>
        <ng-container matColumnDef="orden">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Orden </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.orden > 0">{{element.orden}}</div>
                <div *ngIf="element.orden === 0"> -- </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="fotos">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Fotos </th>
            <td mat-cell *matCellDef="let element"><div (click)="dialogo('CIP', element.id_propiedad)"><div style="float: left; margin-top: 5px;">{{element.fotos}}</div>&nbsp;<mat-icon *ngIf="element.estado" style="cursor: pointer; size: 10px;" title="Agregar">add_circle_outline</mat-icon></div></td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Estado</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle checked="{{element.estado}}" (change)="estado(element.id_propiedad, $event)"></mat-slide-toggle>
            </td>
        </ng-container>
        <ng-container matColumnDef="publicar">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Publicar</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle *ngIf="element.fotoP > 0" checked="{{element.publicar}}" (change)="publicar(element.id_propiedad, $event)"></mat-slide-toggle>
                <div *ngIf="element.fotoP === 0">Falta foto principal</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="destacar">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Destacada</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle *ngIf="element.fotoP > 0" checked="{{element.destacada}}" (change)="destacar(element.id_propiedad, $event)"></mat-slide-toggle>
                <div *ngIf="element.fotoP === 0">Falta foto principal</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="vend_arr">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Vendido/Arrendado</th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle checked="{{element.vend_arr}}" (change)="vendArr(element.id_propiedad, $event)"></mat-slide-toggle>
            </td>
        </ng-container>
        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Acciones </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.urlF !== ''" style="width: 100%;"><iframe [src]="element.urlF" width="89" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
                <mat-icon style="cursor: pointer; margin-right: 5px;" title="Orden" (click)="dialogo('OP', element.id_propiedad)">swap_vertical_circle</mat-icon>
                <mat-icon *ngIf="element.destacada" [ngStyle]="{color: element.colorD}" style="cursor: pointer; margin-right: 10px;" title="Organizar destacada" (click)="dialogo('DP', element.id_propiedad)">dashboard</mat-icon>
                <mat-icon *ngIf="element.estado" style="cursor: pointer;" title="Editar" (click)="dialogo('EP', element.id_propiedad)">create</mat-icon>
                <mat-icon *ngIf="!element.estado" style="cursor: default; color: lightgrey;" title="Editar">create</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[30, 60, 90]" showFirstLastButtons></mat-paginator>
    <div style="z-index:5; position : fixed; float: right; bottom: 5%; right: 2%; margin-bottom : 0px;">
        <a mat-fab [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="font-weight: bold;" (click)="dialogo('CP', '')"><i class="material-icons">add</i></a>
    </div>
</div>
