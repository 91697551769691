import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  tbl: string;
  idM: string;
  tit: string;
  btn: string;
}

export interface eleMoneda {
  estado: string;
  id_tipo_prop: string;
  tipo_prop: string;
}

@Component({
  selector: 'app-moneda',
  templateUrl: './moneda.component.html',
  styleUrls: ['./moneda.component.scss']
})
export class MonedaComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['moneda', 'estado', 'accion'];

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private dashboard: DashboardComponent) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(tabla, id) {
    let titulo = '';
    let boton = '';

    switch (tabla) {
      case 'CM':
        titulo = 'Nueva Moneda';
        boton = 'Crear Moneda';
        break;
      case 'EM':
        titulo = 'Editar Moneda';
        boton = 'Editar Moneda';
        break;
    }
    this.dialog.open(DialogoMoneda, {
      width: '70%',
      data: {tbl: tabla, idM: id, tit: titulo, btn: boton}
    })
  }

  ngAfterViewInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsByOrder('t_moneda', 'moneda').subscribe((monedas: any) => {
      this.dataSource = new MatTableDataSource<eleMoneda>(monedas);
      this.dataSource.paginator = this.paginator;
      localStorage.setItem('load', 'N');
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const objM = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_moneda', id, objM).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoMoneda {
  moneda = {
    estado: true,
    fecha_crea: new Date().getTime(),
    id_moneda: '',
    moneda: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoMoneda>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (dialog.tbl === 'EM') {
      this.generalService.getAllDocumentsConditional('t_moneda', 'id_moneda', '==', dialog.idM).subscribe((moneda: any) => {
        this.moneda = moneda[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    switch (this.dialog.tbl) {
      case 'CM':
        if (this.moneda.moneda !== '') {
          this.generalService.newDocument(this.moneda, 't_moneda').then(successI => {
            const update = {
              id_moneda: successI.id
            };
            this.generalService.updateDocument('t_moneda', successI.id, update).then(successU2 => {
              this.openSnackBar('La moneda se ha creado correctamente.', 'Nueva Moneda');
              this.dialogo.close();
            });
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Nueva Moneda');
        }
        break;
      case 'EM':
        if (this.moneda.moneda !== '') {
          this.generalService.updateDocument('t_moneda', this.moneda.id_moneda, this.moneda).then(successU2 => {
            this.openSnackBar('La moneda se ha editado correctamente.', 'Editar Moneda');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Editar Moneda');
        }
        break;
    }
  }
}
