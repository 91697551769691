import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class StorageService{
    constructor(private http: HttpClient){}

	public cargarWeb(imagen: string, idProp: string, num: number, archivoParaSubir: File){
		const formData = new FormData();
        const tipo = archivoParaSubir.name.split('.');
        const fecha = new Date().getTime();
		let largo = tipo.length - 1;
        let tipoF = tipo[largo];

        if (imagen === 'I') {
            formData.append('icono', archivoParaSubir, idProp + '_' + fecha + '.' + tipoF); 
    
            return this.http.post('https://ck.colladokunstmann.cl/cargarWebI.php', formData);
        } else {
            if (imagen === 'L') {
                formData.append('logo', archivoParaSubir, idProp + '_' + fecha + '.' + tipoF); 
        
                return this.http.post('https://ck.colladokunstmann.cl/cargarWebL.php', formData);
            } else {
                formData.append('propiedad', archivoParaSubir, idProp + '_' + fecha + '_' + num + '.' + tipoF); 
        
                return this.http.post('https://ck.colladokunstmann.cl/cargarPropI.php', formData);
            }
        }
    }
}