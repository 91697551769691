<div class="container">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)">
        <mat-icon title="Volver" style="cursor: pointer; font-size: 35px;" (click)="volver()">reply</mat-icon>
        <h2 style="margin-left: 45%; margin-top: -35px;">CONSULTAS</h2>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 30%;">
                <span class="input-group-text" id="cbPais" style="background-color: white; border: none;">&nbsp;</span>
            </div>
            <select class="custom-select" id="txtFechaI" style="width: 20%;" [(ngModel)]="mesAct" (change)="buscar()">
                <option *ngFor="let mes of meses" [value]="mes.num">{{mes.mes}}</option>
            </select>
            <select class="custom-select" id="txtFechaI" style="width: 20%;" [(ngModel)]="agnoAct" (change)="buscar()">
                <option *ngFor="let agno of agnos" [value]="agno.agno">{{agno.agno}}</option>
            </select>
            <div class="input-group-prepend" style="width: 30%;">
                <span class="input-group-text" id="cbPais" style="background-color: white; border: none;">&nbsp;</span>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_crea | date:'dd-MM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
        </ng-container>
        <ng-container matColumnDef="asunto">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Asunto </th>
            <td mat-cell *matCellDef="let element"> {{element.asunto}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Teléfono </th>
            <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Estado</th>
            <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
        </ng-container>
        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Acciones </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon style="cursor: pointer;" title="Ver información" (click)="dialogo('VI', element.id_consulta)">visibility</mat-icon>&nbsp;&nbsp;
                <mat-icon style="cursor: pointer;" title="Actualizar estado" (click)="dialogo('AI', element.id_consulta)">replay</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    <div style="z-index:5; position : fixed; float: right; bottom: 5%; right: 2%; margin-bottom : 0px;" (click)="dialogo('CC', '')">
        <a mat-fab [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="font-weight: bold;"><i class="material-icons">add</i></a>
    </div>
</div>
