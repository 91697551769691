import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  tbl: string;
  idR: string;
  tit: string;
  btn: string;
}

export interface eleRegion {
  estado: string;
  id_pais: string;
  id_region: string;
  region: string;
}

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['pais', 'region', 'estado', 'accion'];

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private dashboard: DashboardComponent) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(tabla, id) {
    let titulo = '';
    let boton = '';

    switch (tabla) {
      case 'CR':
        titulo = 'Nueva Región';
        boton = 'Crear Región';
        break;
      case 'ER':
        titulo = 'Editar Región';
        boton = 'Editar Región';
        break;
    }
    this.dialog.open(DialogoRegion, {
      width: '70%',
      data: {tbl: tabla, idR: id, tit: titulo, btn: boton}
    })
  }

  ngAfterViewInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsByOrder('t_region', 'region').subscribe((regiones: any) => {
      if (regiones.length > 0) {
        let cant = 1;

        regiones.forEach((region: any) => {
          this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', region.id_pais).subscribe((pais: any) => {
            //this.dataSource = new MatTableDataSource(paises);
            region.id_pais = pais[0].pais;

            if (cant === regiones.length) {
              this.dataSource = new MatTableDataSource<eleRegion>(regiones);
              this.dataSource.paginator = this.paginator;
              localStorage.setItem('load', 'N');
            }
            cant++;
          });
        });
      } else {
        this.dataSource = new MatTableDataSource<eleRegion>(regiones);
        this.dataSource.paginator = this.paginator;
        localStorage.setItem('load', 'N');
      }
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const objR = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_region', id, objR).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoRegion {
  region = {
    estado: true,
    id_pais: '',
    id_region: '',
    region: ''
  };
  paises;

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoRegion>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    this.generalService.getAllDocumentsConditional('t_pais', 'estado', '==', true).subscribe((paises: any) => {
      this.paises = paises;
    });
    
    if (dialog.tbl === 'ER') {
      this.generalService.getAllDocumentsConditional('t_region', 'id_region', '==', dialog.idR).subscribe((region: any) => {
        this.region = region[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    switch (this.dialog.tbl) {
      case 'CR':
        if (this.region.id_pais !== '' && this.region.region !== '') {
          this.generalService.newDocument(this.region, 't_region').then(successI => {
            const update = {
              id_region: successI.id
            };
            this.generalService.updateDocument('t_region', successI.id, update).then(successU2 => {
              this.openSnackBar('La región se ha creado correctamente.', 'Nueva Región');
              this.dialogo.close();
            });
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Nueva Región');
        }
        break;
      case 'ER':
        if (this.region.id_pais !== '' && this.region.region !== '') {
          this.generalService.updateDocument('t_region', this.region.id_region, this.region).then(successU2 => {
            this.openSnackBar('La región se ha editado correctamente.', 'Editar Región');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Editar Región');
        }
        break;
    }
  }
}
