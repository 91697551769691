<div class="container">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)"><mat-icon (click)="volver()" title="Volver" style="cursor: pointer; font-size: 35px;">reply</mat-icon><h2 style="margin-left: 45%; margin-top: -35px;">USUARIOS</h2></div>
    <label>(Contraseña por defecto: NEW2020PASS)</label>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre_01}} {{element.apellido_01}} {{element.apellido_02}} </td>
        </ng-container>
        <ng-container matColumnDef="perfil">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Perfil </th>
            <td mat-cell *matCellDef="let element"> {{element.id_perfil}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Mail </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;"> Estado </th>
            <td mat-cell *matCellDef="let element"><mat-slide-toggle checked="{{element.estado}}" (change)="estado(element.id_usuario, $event)"></mat-slide-toggle></td>
        </ng-container>
        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="text-align: center; font-weight: bold;">Acciones</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.estado" (click)="dialogo('EU', element.id_usuario)" style="cursor: pointer;" title="Editar">create</mat-icon>
                <mat-icon *ngIf="!element.estado" style="cursor: pointer; color: #666666;" title="Editar">create</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    <div style="z-index:5; position : fixed; float: right; bottom: 5%; right: 2%; margin-bottom : 0px;" (click)="dialogo('CU', '')">
        <a mat-fab [ngStyle]="{backgroundColor: authenticationService.localStorageItem('barra'), color: authenticationService.localStorageItem('letra_enc')}" style="font-weight: bold;"><i class="material-icons">add</i></a>
    </div>
</div>
