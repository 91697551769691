import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from './servicio/general.service';
import { SendEmailService } from './servicio/send-email.service';
import { StorageService } from './servicio/storage.service';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EncabezadoComponent } from './web/encabezado/encabezado.component';
import { PieComponent } from './web/pie/pie.component';
import { HomeComponent } from './web/home/home.component';
import { NosotrosComponent } from './web/nosotros/nosotros.component';
import { PropiedadesComponent } from './web/propiedades/propiedades.component';
import { DialogoPropiedades } from './web/propiedades/propiedades.component';
import { ContactoComponent } from './web/contacto/contacto.component';
import { ServiciosComponent } from './web/servicios/servicios.component';
import { LoginComponent } from './intranet/login/login.component';
import { DashboardComponent } from './intranet/dashboard/dashboard.component';
import { ConsultaComponent } from './intranet/consulta/consulta.component';
import { DialogoConsulta } from './intranet/consulta/consulta.component';
import { UsuarioComponent } from './intranet/usuario/usuario.component';
import { DialogoUsuario } from './intranet/usuario/usuario.component';
import { PropiedadComponent } from './intranet/propiedad/propiedad.component';
import { DialogoPropiedad } from './intranet/propiedad/propiedad.component';
import { ConfigurarComponent } from './intranet/configurar/configurar.component';
import { TipoPropComponent } from './intranet/tipo-prop/tipo-prop.component';
import { DialogoTipoProp } from './intranet/tipo-prop/tipo-prop.component';
import { MonedaComponent } from './intranet/moneda/moneda.component';
import { DialogoMoneda } from './intranet/moneda/moneda.component';
import { PaisComponent } from './intranet/pais/pais.component';
import { DialogoPais } from './intranet/pais/pais.component';
import { RegionComponent } from './intranet/region/region.component';
import { DialogoRegion } from './intranet/region/region.component';
import { ComunaComponent } from './intranet/comuna/comuna.component';
import { DialogoComuna } from './intranet/comuna/comuna.component';
import { DetalleComponent } from './web/detalle/detalle.component';
import { DialogoDetalle } from './web/detalle/detalle.component';

@NgModule({
  declarations: [
    AppComponent,
    EncabezadoComponent,
    PieComponent,
    HomeComponent,
    NosotrosComponent,
    PropiedadesComponent,
    DialogoPropiedades,
    ContactoComponent,
    ServiciosComponent,
    LoginComponent,
    DashboardComponent,
    ConsultaComponent,
    DialogoConsulta,
    UsuarioComponent,
    DialogoUsuario,
    PropiedadComponent,
    DialogoPropiedad,
    ConfigurarComponent,
    TipoPropComponent,
    DialogoTipoProp,
    MonedaComponent,
    DialogoMoneda,
    PaisComponent,
    DialogoPais,
    RegionComponent,
    DialogoRegion,
    ComunaComponent,
    DialogoComuna,
    DetalleComponent,
    DialogoDetalle
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSnackBarModule,
    HttpClientModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgbModule
  ],
  entryComponents: [
    DialogoPropiedades,
    DialogoConsulta,
    DialogoUsuario,
    DialogoPropiedad,
    DialogoTipoProp,
    DialogoMoneda,
    DialogoPais,
    DialogoRegion,
    DialogoComuna,
    DialogoDetalle
  ],
  providers: [GeneralService, SendEmailService, Title, StorageService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  //{ provide: LocationStrategy, useClass: HashLocationStrategy }
  bootstrap: [AppComponent]
})
export class AppModule { }
