import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatPaginator } from '@angular/material/paginator';

export interface Dialogo {
  tbl: string;
  idC: string;
  tit: string;
  btn: string;
}

export interface eleComuna {
  estado: string;
  id_pais: string;
  id_region: string;
  id_comuna: string;
  comuna: string;
}

@Component({
  selector: 'app-comuna',
  templateUrl: './comuna.component.html',
  styleUrls: ['./comuna.component.scss']
})
export class ComunaComponent implements AfterViewInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['pais', 'region', 'comuna', 'estado', 'accion'];

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private dashboard: DashboardComponent) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dialogo(tabla, id) {
    let titulo = '';
    let boton = '';

    switch (tabla) {
      case 'CC':
        titulo = 'Nueva Comuna';
        boton = 'Crear Comuna';
        break;
      case 'EC':
        titulo = 'Editar Comuna';
        boton = 'Editar Comuna';
        break;
    }
    this.dialog.open(DialogoComuna, {
      width: '70%',
      data: {tbl: tabla, idC: id, tit: titulo, btn: boton}
    })
  }

  ngAfterViewInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.scAllDocumentsByOrder('t_comuna', 'comuna').subscribe((comunas: any) => {
      if (comunas.length > 0) {
        let cant = 1;

        comunas.forEach((comuna: any) => {
          this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', comuna.id_pais).subscribe((pais: any) => {
            this.generalService.getAllDocumentsConditional('t_region', 'id_region', '==', comuna.id_region).subscribe((region: any) => {
              //this.dataSource = new MatTableDataSource(paises);
              comuna.id_pais = pais[0].pais;
              comuna.id_region = region[0].region;
  
              if (cant === comunas.length) {
                this.dataSource = new MatTableDataSource<eleComuna>(comunas);
                this.dataSource.paginator = this.paginator;
                localStorage.setItem('load', 'N');
              }
              cant++;
            });
          });
        });
      } else {
        this.dataSource = new MatTableDataSource<eleComuna>(comunas);
        this.dataSource.paginator = this.paginator;
        localStorage.setItem('load', 'N');
      }
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const objC = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_comuna', id, objC).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoComuna {
  comuna = {
    estado: true,
    id_pais: '',
    id_region: '',
    id_comuna: '',
    comuna: ''
  };
  paises;
  regiones;

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoComuna>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    this.generalService.getAllDocumentsConditional('t_pais', 'estado', '==', true).subscribe((paises: any) => {
      this.paises = paises;
    });
    
    if (dialog.tbl === 'EC') {
      this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', dialog.idC).subscribe((comuna: any) => {
        this.generalService.getAllDocumentsConditional2('t_region', 'id_pais', '==', comuna[0].id_pais, 'estado', '==', true).subscribe((regiones: any) => {
          this.regiones = regiones;
          this.comuna = comuna[0];
        });
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    switch (this.dialog.tbl) {
      case 'CC':
        if (this.comuna.id_pais !== '' && this.comuna.id_region !== '' && this.comuna.comuna !== '') {
          this.generalService.newDocument(this.comuna, 't_comuna').then(successI => {
            const update = {
              id_comuna: successI.id
            };
            this.generalService.updateDocument('t_comuna', successI.id, update).then(successU2 => {
              this.openSnackBar('La comuna se ha creado correctamente.', 'Nueva Comuna');
              this.dialogo.close();
            });
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Nueva Comuna');
        }
        break;
      case 'EC':
        if (this.comuna.id_pais !== '' && this.comuna.id_region !== '' && this.comuna.comuna !== '') {
          this.generalService.updateDocument('t_comuna', this.comuna.id_comuna, this.comuna).then(successU2 => {
            this.openSnackBar('La comuna se ha editado correctamente.', 'Editar Comuna');
            this.dialogo.close();
          });
        } else {
          this.openSnackBar('Complete la información solicitada.', 'Editar Comuna');
        }
        break;
    }
  }

  cargarReg() {
    this.generalService.getAllDocumentsConditional2('t_region', 'id_pais', '==', this.comuna.id_pais, 'estado', '==', true).subscribe((regiones: any) => {
      this.regiones = [];
      this.regiones = regiones;
    });
  }
}
